const routes = [
    {
        path: "/assessment/bulk/entry/:sessionId",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "assessment_assessmenttypebysession"
        }
    }
]

export default routes;

