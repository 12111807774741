import {getSuccessRoutePage, setSuccessRoutePage} from "@/modules/makePayment/router/middleware";

const routes = [
    {
        path: '/make-payment',
        component: () => import('../pages/Index.vue'),
        props:{type:'bau'}
    },
    {
        path: '/mentora/make-payment',
        component: () => import('../pages/Index.vue'),
        props:{type:'mentora'}
    },
    {
        path: '/housing/make-payment',
        component: () => import('../pages/Index.vue'),
        props:{type:'housing'}
    },
    {
        path: '/payment-success',
        component: () => import('../pages/Success.vue'),
        beforeEnter: (to, from, next) => {
            if(getSuccessRoutePage()){
                setSuccessRoutePage(false)
                next()
            }else{
                next({
                    path: 'make-payment'
                })
            }
        }
    },

]

export default routes
