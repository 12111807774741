const routes = [
    {
        path: "/housing/buildings/days",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "housingbuilding_days"
        }
    },

];

export default routes;
