import HousingReservationsListService from '@/services/HousingReservationsListService'

const module = {
    namespaced: true,
    state: () => ({
        token: null,
    }),
    getters: {
        getReservationToken(state) {
            return  JSON.parse(localStorage.getItem('tenant_token'))
        },

    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setUser(state, user) {
            state.user = user;
        },
        clearUserAndToken(state) {
            state.user = {}
            state.token = null;
        },

    },
    actions: {
        async setToken({commit}) {
            await HousingReservationsListService.studentToken()
                             .then(response => {
                                const token =response.data.data.token
                                commit('setToken',token)
                                 localStorage.setItem('tenant_token',JSON.stringify(token))
                             })
        },
    }
}

export default module;
