let goReservationSystemForm=false

export const setGoReservationSystemForm=(status)=>{
    goReservationSystemForm=status
}

export  const getGoReservationSystemForm=()=>goReservationSystemForm

let isSuccessRoutePage=false

export const setSuccessRoutePage=(status)=>{
    isSuccessRoutePage=status
}

export const getSuccessRoutePage=()=>isSuccessRoutePage
