const routes = [
    {
        path: "/leave/absences",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "leaveofabsence_index"
        }
    },
    {
        path: "/leave/absences/form",
        component: () => import("../pages/LeaveForm"),
        meta: {
            isAuthenticated: true,
            isStudent: true
        }
    },
    {
        path: "/leave/absences/request/form",
        component: () => import("../pages/LeaveRequestForm"),
        meta: {
            isAuthenticated: true,
        }
    },
    {
        path: "/leave/absences/:id",
        component: () => import("../pages/Show"),
        meta: {
            isAuthenticated: true,
            permission: "leaveofabsence_show"
        }
    }
];

export default routes;
