export default class LocalStorage {
    static get(name) {
        try{
            let data = localStorage.getItem(name);
            if (!data) return data;
            return JSON.parse(data);
        }
        catch {

        }
        return null;
    }

    static set(name, val) {
        return localStorage.setItem(name, JSON.stringify(val));
    }

    static add(name, addVal) {
        let oVal = LocalStorage.get(name);
        let nVal = oVal.concat(addVal);
        LocalStorage.set(name, nVal);
    }

    static remove (name){
        localStorage.removeItem(name);
    }
}
