const translations = {
    tr: "Türkçe",
    en: "English",

    // A
    apartment: "Apartment",
    agencies:"Agencies",
    assign_doesnt_have_payment_approval: "Assign Doesnt Have Payment Approval",
    assign_doesnt_have_course_registration: "Assign Doesnt Have Course Registration",
    are_you_sure_to_start_process: "Are You Sure To Start Process",
    anticipated_end_date: "Anticipated End Date",
    add_reservation:"Add Reservation",
    advisor_student_report:"Advisor Student Report",
    agreement_files:"Agreement Files",
    add_to_cart: "Add to Cart",
    accompany_name_surname:"Accompany Name Surname",
    accompany_email:"Accompany E-mail",
    accompany_phone:"Accompany Phone",
    airport_transfer_information:"Airport Transfer information",
    add_request_form: "Add Request Form",
    airline_name:"Airline Name",
    airport_name:"Airport Name",
    airport_transfers_list:"Airport Transfers List",
    airport_transfer:"Airport Transfer",
    arrival_date:"Arrival Date and Time",
    available_roles:"available Roles",
    are_you_a_bau_student_or_staff:"Are you a BAU/Mentora student or staff?",

    academic_season_term_applied: "Academic Season-Term Applied",
    advisor_approval_statuses: "Advisor Approval Statuses",
    aging_summary_report: "Aging Summary Report",
    approved_students: "Approved Students",
    pending_students: "Pending Students",
    declined_students: "Declined Students",
    availability_of_rooms: "Availability Of Rooms",
    absent: "Absent",
    headcount_by_major_report: "Headcount By Major Report",
    absence_report: "Absence Report",
    academic_unit_office: "Academic Unit Office",
    academic_year: "Academic Year",
    academic_year_of_registration: "Academic Year of Registration",
    academic_year_of_initial_registration: "Academic Year of Initial Registration",
    academic_reports: "Academic Reports",
    acceptance_data: "Acceptance Data",
    acceptance_date: "Acceptance Date",
    admission_data: "Admission Data",
    accepttance_status: " Acceptance Status",
    account_code: "Account Code",
    account_holder: "Account Holder",
    account_information: "Account Information",
    account_number: "Account id",
    action: "Action",
    active: "Active",
    active_semester: "Active Semester",
    adaptation_request: "Adaptation Request",
    add: "Add",
    add_exam_score: "Add Exam Score",
    add_expense_detail:"Add Expense Detail",
    add_bank_credit: "Add Student Credit (Deposit) Bank Account",
    add_course: "Add course",
    add_course_transfer: "Add Course Transfer",
    add_discount: "Add Discount",
    add_education_credit: "Add Education Credit",
    add_module: "Add Module",
    add_new_course: "Add New Course",
    add_offset: "Add Offset",
    e_payment: "E-payment System",
    e_payment_success_message: "Your payment has been completed.",
    e_payment_alert_message:" If you need to make a payment that is not listed here, please call\n" +
        "                            <strong>202-644-7213</strong> or send an email to <strong>bursar@bau.edu</strong>.",
    add_offset_payment: "Add Offset Payment",
    add_option: "Add Option",
    add_payment: "Add Payment",
    add_payment_plan: "Add Billing",
    add_pdf: "Add Pdf",
    add_preference: "Add Preference",
    add_refund: "Add Refund",
    add_scholarship: "Add Scholarship",
    add_slot: "Add Slot",
    add_description: "Add Description",
    enter_explanation: "Enter Explanation",
    added_by: "Added By",
    added_course_transfer: "Added Course Transfer",
    added_date: "Added Date",
    additional_lecturer: "Additional Instructor",
    additional_lecturers: "Additional Instructors",
    address: "Address",
    admissionReport: "Admission Report",
    address_2: "Full Address",
    address_en: "Address (EN)",
    address_information: "Address Information",
    address_update: "Update your Address",
    advance_payment: "Advance Payment",
    advisor: "Advisor",
    advisor_id: "Advisor ID",
    advisor_name: "Advisor's Name",
    advisor_of_project: "Project Advisor",
    advisor_of_thesis: "Thesis Advisor",
    advisor_surname: "Advisor's Surname",
    advising_of_students: "Advising Of Students",
    adjunct: "Adjunct",
    adjunct_pay: "Adjunct Pay",
    affect_ratio:"Affect Ratio",
    affiliated_curriculum: "Affiliated Curriculum",
    already_available: "Already Available",
    all: "All",
    all_exam_scores: "All Exam Scores",
    allow_classroom_overlap: "Allow Classroom Overlapping",
    allow_instructor_overlap: "Allow Instructor Overlapping",
    allow: "Allow",
    all_students: "All Students",
    alumni: "Alumni",
    amount: "Amount",
    amount_paid: "Amount paid",
    amount_tl: "Amount (TL)",
    amount_to_be_paid: "Amount to be Paid",
    and: "and",
    api_config: "Api Config",
    application_completion_status: "Application Completion Status",
    application_date: "Date of Application",
    application_follow_link: "Application Follow Link",
    attendance:"Attendance",
    application_information: "Application Information",
    application_new: "Application New",
    application_number: "Application Number",
    application_period: "Period of Application ",
    application_rules: "Application Rules",
    application_status: "Application Status",
    application_success: "Application Success",
    application_success_info: "Application Success İnfo",
    application_summary: "Application Summary",
    application_system: "Application System",
    application_type: "Application Type",
    application_update_success: "Application Update Success",
    application_withdraw_display: "Application Withdraw Display",
    applications: "Applications",
    applied_faculty: "Applied School",
    applied_program: "Applied Program",
    apply: "Apply",
    apply_cash_discount: "Apply Cash Discount",
    apply_scholarship: "Apply for Scholarship",
    approval_phase: "Approval Phase",
    approval_procedures: "Approval Procedures",
    approval_registration_succes_msg:
        "E-Registration procedure successfully completed.",
    approval_status: "Approval Status",
    approve: "Approve",
    approve_or_reject_confirm: "Approve or Reject (Confirmation Modal)?",
    approved: "Approved",
    approved_go: "Send for approvel",
    approved_by: "Approved by",
    approved_date: "Approved Date",
    approvers: "Approvers",
    are_you_sure_to_apply: "Are you sure to apply",
    are_you_sure_to_cancel: "Are you sure you want to cancel?",
    are_you_sure_to_create_a_new_proforma_invoice:
        "Are you sure to create a new proforma invoice",
    are_you_sure_to_copy: "Are you sure to duplicate?",
    are_you_sure_to_delete: "Are you sure you want to delete?",
    are_you_sure_to_give_exceptional_payment_approval:
        "Are you sure to give exceptional payment approval?",
    are_you_sure_to_move: "Are you sure to move",
    are_you_sure_to_remove_exceptional_payment_approval:
        "Are you sure to remove exceptional payment approval?",
    are_you_sure_to_send: "Are you sure to send",
    are_you_sure_to_withdraw:
        "Are you sure that you want to withdraw from the course?",
    are_you_sure_want_save: "Are you sure want save",
    are_you_sure_you_want_to_take_the_x_course:
        "Are you sure you want to take the course '{course_name}'-'{course_code}'?",
    are_you_sure_you_want_to_update_the_approval_status:
        "Are you sure you want to update your approval status?",
    asal: "Department of Recruitment",
    asal_delay_reasons: "Asal Delay Reasons",
    asal_maximum_time_reasons: "Asal Maximum Time Reasons",
    asal_type: "Asal Type",
    assessment_type: "Assessment Type",
    assessment_type_name:"Assessment Type Name",
    assessment_note_entry:"Assessment Grade Entry",
    asynchronous: "Asynchronous",
    at_which_unit: "At Which Unit",
    attendance_hours: "Attendance Hours",
    attendance_date: "The Date Attendance is Taken",
    attendance_list: "Attendance List",
    attendance_report: "Attendance Report",
    attendees: "Attendees",
    attention: "Attention",
    attention_for_thesis_and_project:
        "During the Course Registration Period you must register for the course. Thesis 1-Seminar/Project/Proficiency displayed on the Course Selection Webpage",
    attendance_entry_tracking_report: "Attendance Entry Tracking Report",
    attach_supporting_file: "Please, attach supporting documents:",
    authorizations: "Authorizations",
    available_hours: "Available Hours",
    average_system_100: "Average System 100",
    average_system_4: "Average System 4",
    attendance_entry: "Attendance Entry",
    address_no_and_street: "Address: (No. and Street)",
    accused_person_initial_complaint_form: "Name(s) of person or persons you believe sexually harassed or discriminated against you",
    automotive: "Automotive",
    airport: "Airport",
    airport_transfer_request: "Airport Transfer Request",
    add_note_active_information: "<span>The Grade Change Form - Faculty is used to request grade adjustments when faculty cannot make changes directly in the system. Requests for F-1 students with failing grades due to attendance will be automatically rejected.</span>\n" +
        "                                        <small>\n" +
        "                                            <a href=\"https://na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhAusCYog6vgMpNSAb173pxmfWctx8-VLTbQcKOikFpKmACiq4WbQxpvXJC8XSVdLxU*\" target=\"_blank\" >\n" +
        "                                               Click for Grade Change Form" +
        "                                            </a>\n" +
        "                                        </small>",
    add_note_passive_information: "<span>Grade Entry period is closed now. Only Registrar can make changes to the grades in the system.</span>\n" +
        "                                        <small>\n" +
        "                                            <a href=\"https://na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhAusCYog6vgMpNSAb173pxmfWctx8-VLTbQcKOikFpKmACiq4WbQxpvXJC8XSVdLxU\" target=\"_blank\" >\n" +
        "                                              Click for Grade Change Form" +
        "                                            </a>\n" +
        "                                        </small>",
    absent_semester: "I will be Absent in the Semester/Year of",
    apply_to: "Apply To",
    agency_code: "Agency Code",
    agency_name: "Agency Name",
    agency_information: "Agency Information",
    // B
    back: "Back",
    bahcesehir_university: "Bay Atlantic University(BAU)",
    bay_atlantic_university: "Bay Atlantic University",
    balance: "Balance",
    balance_overflow: "Insufficient balance",
    bank: "Bank",
    bank_additional_installment: "Bank Additional Installment",
    bank_credit: "Bank Credit",
    bank_credit_cash: "Bank Credit Cash",
    bank_id: "Bank Name",
    bank_name: "Name of Bank",
    batch_gno_calculation:
        "Calculation of Institutional Cumulative Grade Point Average",
    batch_update_due_date: "Batch Update Due Date ",
    bachelor_commission:"Bachelor Commission",
    bed_hold_request:"Bed Hold Request",
    bau_grade_conversion: "Bau Grade Conversion",
    bau_grades: "Bau Grades",
    bau_language_exam_info: "Bau Language Exam İnfo",
    bau_language_exam_question: "Bau Language Exam Questions",
    before_leave_of_absence: "Have you taken leave of absence at school?",
    birthdate: "Birthdate",
    birthplace: "Birthplace",
    board_decision: "Board Decision",
    board_decisions: "Board Decisions",
    board_members: "Board Members",
    board_types: "Board Types",
    bonus_course_credit_limit: "Bonus Course Credit Limit",
    branch_name: "Branch Name",
    branch_type: "Branch Type",
    browse: "Browse",
    building: "Building",
    buildings: "Buildings",
    building_id: "Building Id",
    building_name: "Building Name",
    bulk_document_creation: "Bulk Document Creation",
    bulk_graduation: "Bulk Graduation",
    bulk_import: "Bulk Import",
    building_room: "Building / Room No.:",
    bedroom: "Bedroom",
    bathroom: "Bathroom",
    application_images: "Application Images",
    bed_type: "Bed Type",
    bed:"Bed",
    bau_email: "BAU E-mail",
    building_cleaning_list:"Building Cleaning List",

    // C
    currently_enrolled: "Currently Enrolled",
    catalogue_year: "Catalogue Year",
    catalogue_semester: "Catalogue Semester",
    calculate: "Calculate",
    credit_hours_generated_report: "Credit Hours Generated Report",
    calculated_cgpa: "Calculated Cgpa",
    calculated_gpa: "Calculated Gpa",
    campus: "Campuses",
    campus_address: "Campus Address",
    campus_id: "Campus Id",
    campus_name: "Campus Name",
    can_approvers: "Authorized Approvers",
    can_be_repeated: "Can it be repeated?",
    can_take_same_semester: "Can be taken in the same semester",
    can_not_empty_classrooms: "Classroom list can not be empty.",
    cancel: "Cancel",
    cancel_application: "Cancel Application",
    cancel_to_move: "Cancel To Move",
    cancellation: "Cancellation",
    cancellation_amount: "Cancellation_Amount",
    cancelled: "Cancelled",
    cannot_overflow_balance: "Cannot Overflow Balance",
    capacity: "Capacity",
    card_holder_address: "Cardholder Address",
    card_holder_name: "Cardholder Name",
    card_holder_phone: "Cardholder Phone",
    card_name: "Card Name",
    card_name_en: "Card Name (EN)",
    card_no: "Card Number",
    card_number: "Card Number",
    card_owner: "Card Owner",
    change_program:"Change Program",
    card_status: "Card Status",
    card_type: "Card Type",
    cash: "Cash",
    cash_collection_report: "Cash Collection Report",
    cash_discount_rate: "Cash Discount Rate",
    cash_discount: "Upfront Discount",
    cash_in_advance: "Cash in Advance",
    cash_or_installed: "Cash / Installed",
    cash_payment: "Cash Payment",
    change_room_name:"Change Room Name",
    change_bed_name:"Change Bed Name",
    change_floor:"Change Floor",
    change_building_name:"Change Building Name",
    cash_payment_type: "Cash Payment Type",
    complaint_person:"Complaint Person",
    category: "Category",
    cert_type: "Certificate Type",
    certificate: "Certificate",
    certificate_id: "Certificate İd",
    certificate_information: "Certificate İnformation",
    certificate_name: "Certificate Name",
    certificate_name_en: "Certificate Name En",
    certificateshow: "Certificateshow",
    current_address: "Current Address",
    cgpa: "CGPA",
    change: "Change",
    change_your_password: "Change Your Password",
    change_price: "Change Price",
    change_section: "Change Section",
    change_student_group_radio: "Change Student Group",
    changes_will_be_saved:
        "The changes will be saved. Do you confirm the Procees?",
    check: "Check",
    check_out_date: "Check Out Date",
    check_in_date: "Check In Date",
    check_drawer: "Check Drawer",
    check_it: "Verify",
    check_it_title: "Check İt Title",
    calculate_extra_graduate_courses: "Calculate Extra Graduate Courses",
    create_student_semesters: "Create Student Semesters",
    create_reservation_request: "Create Reservation Request",
    check_no: "Check No",
    check_payment_approval: "Verify Payment Approval",
    choose_student_type_confirm_text: "Select New Student Registeration",
    chose_your_double_major: "Chose Your Double Major",
    cities: "Cities",
    city: "City",
    city_id: "County",
    city_name: "State",
    clarification_text: "Clarification Text",
    class: "Class",
    classrate: "Percentile in Class",
    class_applied: "Class Applied",
    class_settings: "Class Settings",
    class_x: "%{class}st Class",
    classroom: "Classroom",
    classroom_list: "Classroom List",
    classroom_: "Classroom_",
    classroom_id: "Classroom ID",
    classroom_name: "Classroom Name",
    classroom_programs: "Classroom Programs",
    classroom_types: "Classroom Types",
    classrooms: "Classrooms",
    classroom_not_selected: "Classroom Not Selected",
    clear: "Clear",
    contract_acceptance: "Contract Acceptance",
    tuition_and_fees: "Tuition And Fess",
    tuition_and_fees_text:"* When students cancel their enrollment within 3 business days of the beginning of a semester. <br>**Only students who receive full scholarship of any kind defined in the scholarship section are required to pay.<br>" +
        "Tuition and fees are not kept stable for cohorts upon registration. Tuition and fees may change each year based on the Board of Trustee’s decision. Students are required to check the webpage and the academic catalog during course registration.\n",
    contract_acceptance_text: " I, the undersigned, have read and understood this agreement and acknowledge receipt of a copy.\n" +
        " It is further understood and agreed that this agreement supersedes all prior or contemporaneous\n" +
        " verbal or written agreements and may not be modified without the written agreement of the\n" +
        " student and the School Official. I also understand that if I default on this agreement, I will be\n" +
        " responsible for payment of any collection fees or attorney fees incurred by BAU. <br><br>" +
        "My signature below signifies that I have read and understood all aspects of this agreement and I\n" +
        " have recognized my legal responsibilities regarding this contract. I acknowledge that I have\n" +
        " received a copy of this document. <br><br>" +
        "Signature of Student:{name} {surname} Date:{date} " +
        "Signature of School Official: Office of the Registrar Date:{date}",
    click_to_choose: "Click To Choose",
    close: "Close",
    closed: "Closed",
    cleaning_schedule_start_date:"Cleaning Schedule Start Date",
    cleaning_schedule_repeat:"Cleaning Schedule Repeat",
    close_edit_at: "Close Edit At",
    close_lessons_only: "Close Courses Only",
    code: "Code",
    coefficient: "BAU Grade Coefficient",
    collection_bank: "Collection Bank",
    collection_report: "Collection Report",
    collection_screen: "Collection Screen",
    collective_consultant_appointment: "Collective Consultant Appointment",
    columns: "Columns",
    comment: "Comment",
    communication_address: "Contact Address",
    compass_dates: "Compass Dates",
    company_name: "Company Name",
    company_name_en: "Company Name (EN)",
    complaint_petition: "Complaint Petition",
    complete_registration: "Complete your Registration",
    completed_akts_credit: "Completed ECTS Credits",
    completed_credit: "Completed Credits",
    completed_ects_credit: "Completed Ects Credit",
    completed_slots: "Completed Slots",
    completed_required_course_count: "Completed Required Course Count",
    completed_required_ects_credit: "Completed Required Ects Credit",
    completed_required_credit: "Completed Required Credit",
    completed_gep_course_count: "Completed GEP Course Count",
    completed_gep_ects_credit: "Completed GEP Ects Count",
    completed_gep_credit: "Completed GEP Credit",
    completed_dep_course_count: "Completed DEP Course Count",
    completed_dep_ects_credit: "Completed DEP Ects Count",
    completed_dep_credit: "Completed DEP Course Credit",
    completed_non_dep_course_count: "Completed NON DEP Course Count",
    completed_non_dep_ects_credit: "Completed NON DEP Ects Count",
    completed_non_dep_credit: "Completed NON DEP Course Credit",
    confirmation: "Confirm",
    conflict_informations: "Overlapping Schedules",
    constants: "Constants",
    contact: "Contact",
    contact_address_information: "Contact Address Information",
    contact_information: "Contact Information",
    contact_information_reports: "Contact İnformation Reports",
    contact_number: "Contact Number",
    course_approval_check: "Course Approval Review",
    contact_support: "Contact Support",
    contents: "Contents",
    continue: "Continue",
    contract_type: "Contract Type",
    conditionally_passed:"Conditionally passed",
    coopcertificate: "Coopcertificate",
    coopstudentcertificate: "Coopstudentcertificate",
    coop_and_public_scholarship: "COOP and Public Scholarship",
    coop_scholarship: "COOP Scholarship",
    course_selection_credit_limit: "Course Selection Credit Limit",
    count: "Count",
    count_number: "Sequence Number",
    countries: "Countries",
    country: "Country",
    country_code: "Country Code",
    country_id: "Country",
    course: "Course",
    course_import_text:
        "Code, Name, Credit, Lab Credit, Credit, Type, School Code, Department Code, Program Code, Grading Scale, Active Status",
    course_merge_section: "LMS Course Merge",
    course_code: "Course Code",
    course_coordinator: "Course Coordinator",
    course_count_to_take: "Course Count To Take",
    course_detail_title: "Course Information",
    course_field_is_required: "Course Field Is Required",
    course_grade_entry_report: "Course Based Grade Entry Report",
    course_id: "Course Id",
    course_history:"Course History",
    course_language: "Language of Instruction",
    course_name: "Course Name",
    course_pool_err: "The Course is available in the Elective Pool",
    course_price: "Course Price",
    course_withdrawal_info: "Students seeking to withdraw from a course, after the\n" +
        "                                    add/drop period, must complete this form. The following terms and <br/> conditions\n" +
        "                                    apply:",
    cancellation_refund_policy: "Cancellation Refund Policy",
    cancellation_refund_policy_text: "If a student elects to withdraw from specific course(s) or completely from the university, the following refund schedule will be used to determine any outstanding financial obligation for which the student may be responsible. Refunds exclude all mandatory fees and deposits, which are non-refundable.<br><br>Time of written notice of withdrawal<br>Up until three business days prior to 1st day of the semester<br>From within 3 business days of the start of the semester through 25% of the semester<br>From 25% through 50% of the semester<br>After 50% of the semester<br><br>Tuition refund*<br>100% of tuition paid<br>50% of tuition paid<br>25% of tuition paid<br>No refund<br><br>A student applicant will be considered a student as of the first day of classes. Request for withdrawal must be initiated on OIS or submitted directly to the registrar to officially withdraw from BAU. No refund will be honored without the withdrawal request initiated on OIS or submitted to the registrar. The official withdrawal date, for the purpose of a refund calculation, will be taken from the date the online request is received on OIS or by the registrar.<br><br>BAU will issue refunds to individuals who have terminated their status as students within 45 days after receipt of the online withdrawal request. If no payment was made, the University will bill the student for the due amount according to the schedule above. If a student is participating in a payment plan and the installment payment is insufficient to cover the student's obligation according to the schedule above, then the university will send the student a bill for the difference.",
    course_registration: "Course Registration",
    course_registration_semester: "Course Registration Period",
    course_registration_completed: "Course Registration Completed",
    course_schedule: "Course Schedule",
    card_code:"Cart Code",
    course_schedule_university : "University Course Schedule",
    course_schedule_format: "Course Code,Section,Instructor Mail,Classroom,Day (1-2-3-4-5-6-7),Start time,End time,Instructor Overlap,Classroom Overlap",
    course_schedule_from_draft: "Course_Schedule_From_Draft",
    course_schedule_will_be_created_from_draft:
        "Course Schedule Will Be Created From Draft",
    course_status: "Course Status",
    course_transfer: "Course Adjustment",
    course_transfer_evaluation: "Course Transfer Evaluation",
    course_transfer_success_text: "Your Course Transfer Request  Success Text",
    course_transfer_verify_text: "Course Transfer Verify Text",
    course_transfers_form: "Application Form for Course Transfer",
    course_type: "Course Type",
    courseprerequisites: "Course prerequisites",
    courses: "Courses",
    courses_you_have_taken_so_for: "Courses You Have Taken So For",
    course_and_slot_logs: "Course and Slot Logs",
    course_withdrawal_request: "Course Withdrawal Request",
    create: "Create",
    course_prerequisites_import_excel:"Course Code,Prerequisites courses,Program codes,Can take Same Semester",
    create_classes: "Create Classes",
    create_document: "Create Document",
    create_pilotage_fee: "Create Pilotage Fee",
    create_proforma_invoice: "Create Proforma İnvoice",
    created_at: "Created At",
    created_by: "Created By",
    creating_fykk_template: "Creating FYKK Template",
    credit: "Credit",
    credit_card: "Credit Card",
    create_session:"Create Session",
    credit_card_info: "Credit Card Info",
    credit_card_number: "Credit Card Number",
    credit_limit: "Credit Limit",
    credit_price: "Credit Price",
    credit_x: "{credit} Credit(s)",
    credits_attempted: "Credits Attempted",
    credits_earned: "Credits Earned",
    current_password: "Current Password",
    currency: "(Unit of) Currency",
    currency_rate: "Currency_Rate",
    current_section: "Current Section",
    current_sem_level: "Current Sem. Level",
    curriculum: "Curriculum",
    curriculums: "Curriculums",
    curriculum_credits: "Curriculum Credits",
    curriculum_credits_report:"Curriculum Credits Report",
    curriculum_ects_credit: "Curriculum Ects Credit",
    customer_group: "Customer Group",
    customer_type: "Customer Type",
    cut_rate: "Cut Rate",
    card_code_note: "(The three- or four-digit code assigned to a customer’s credit card number)",
    course_approval_report: "Course Approval Report",
    course_approval_check_report: "Course Approval Check Report",
    complainant_last_name: "Complainant Last Name",
    complainant_first_name: "Complainant First Name",
    complaint_verification_initial_complaint_form: "This complaint was filed based on my honest belief that _____ has sexually harassed and/or discriminated against me. I hereby verify that the information provided in this complaint is true, correct, and complete, to the best of my knowledge and belief",
    complainant_signature: "Complainant Signature",
    complainant_received_by: "Complainant Received by (Name / Title and Signature)",
    common_areas: "Common Areas",
    Custodial: "Custodial",
    completion_date: "Completion Date",
    credit_transfer: "Credit Transfer",
    course_failure_due_to_attendance_violation:"Course Failure due to Attendance Violation",
    credit_type: "Credit Type",
    change_of_program: "Change of Program",

    // D
    date_took_the_course: "Date Took The Course",
    dates_of_alleged_incidents:"Date To Alleged Incidents",
    date_registered:"Date Registered",
    diploma_date_range: "Diploma Date Range",
    degree_seeking_enrollment_degrees_report:
        "Degree Seeking Enrollment Degrees Report",
    dashboard: "Dashboard",
    data: "Data",
    data_from_system_control: "Data From System Control",
    data_filter: "Data Filter",
    date_info: "Date Information",
    date: "Date",
    date_between: "Date Range",
    time_between: "Time Range",
    date_of_birth: "Date of Birth",
    day: "Day",
    days: "Days",
    day_interval: "Day Interval",
    days_in_a_week:"Days in a week",
    days_of_the_week:"Days of the week",
    dean: "Dean",
    dean_name: "Name of Dean",
    dean_title: "Title of Dean",
    dean_title_en: "Title of Dean",
    debt: "Debt",
    debts: "Debts",
    decision: "Decision",
    definition:"Definition",
    decision_date: "Decision Date",
    decision_number: "Decision Number",
    denied: 'Denied',
    discount_list: "Discount List",
    decline: "Reject",
    decline_reason: " Reason for Rejection",
    declined: "Declined",
    diversity_report: "Diversity Report",
    definitive_registration_information: "Final Registration Information",
    definitive_enrollment_year_information: "Enrollment Year Information",
    demographics_of_current_students: "Demographics of Current Students",
    number_of_current_students_between_selected_dates:
        "Number of Current Students Between Selected Dates",

    degree_of_proximity: "Relationship",
    delay: "Delay",
    delay_1: "Delay",
    delete: "Delete",
    delete_all_students: "Delete All Students",
    dep_course_count: "Dep Course Count",
    dep_course_credit: "Dep Course Credit",
    dep_course_ects_credit: "Dep Course Ects Credit",
    department: "Department",
    department_code: "Department Code",
    department_codes: "Department_Codes",
    department_name: "Department Name",
    departments: "Departments",
    detail: "Detail",
    details: "Details",
    Diploma: "Diploma",
    diploma_annexes: "Diploma Annexes",
    diploma_date: "Diploma Date",
    diploma_information: "Diploma Information",
    diploma_name: "Diploma Name",
    diploma_name_en: "Diploma Name (EN)",
    disability_services: "Disability Services",
    diploma_number: "Diploma Number",
    diploma_point: "Diploma Grade",
    diploma_status: "Diploma Status",
    diploma_supplement: "Diploma Supplement",
    diploma_title: "Award Title",
    diploma_title_en: "Award Title (EN)",
    disability_rate: "Disability Rate",
    disability_type: "Type of Disability",
    disciplinary_committee_decision: "Disciplinary Committe Decision",
    discipline_follow: "Disciplinary Actions ",
    discipline_process: "Disciplinary Process",
    discipline_registry: "Discipline Registry Document",
    disciplinary_action: "Student has disciplinary action.",
    discount: "Discount",
    discount_rate_total: "Total Discount Rate",
    discount_rate_amount: "Total Discount Amount",
    discount_amount: "Discount Amount",
    diploma_and_transcript_program:"Diploma And Transcript Program",
    diploma_concentration:"Diploma Concentration",
    discount_rate: "Scholarship Rate",
    discount_per_credit: "Scholarship per Credit",
    dispatch_postponement_certificate: "Dispatch Postponement Certificate",
    display_systems: "Display Systems",
    distance_sales_agreement: "Distance Sales Agreement",
    district: "District",
    date_of_admission: "date Of Admission",
    district_code: "District Code",
    district_name: "District Name",
    districts: "Districts",
    make_application: "Make an Application",
    do_cancel: "Cancel",
    do_payment: "Do_Payment",
    do_withdraw: "Withdraw",
    do_you_have_language_proficiency:
        "Do you have language proficiency certificate?",
    do_you_have_language_proficiency_missing:
        "Request Exemption from English Preparatory Program",
    do_you_verify_email: "Would you like to update your e-mail?",
    do_you_verify_mobile_no:
        "Would you like to update your mobile phone number?",
    do_you_want_to_close_the_form_with_the_selected_interview_result:
        "Do you want to close the form with the Selected Interview Result?",
    do_you_want_to_complete_registration:
        "Do you want to complete registration?",
    do_you_want_to_print_receipt: "Do you want to print the receipt?",
    document: "Document",
    document_add: "Add Document",
    document_duae_date: "Date to Bring the Document",
    document_due_date: "Document Due Date",
    document_edit: "Edit Document",
    document_is_deleted: "Document is Deleted",
    document_number: "Document Number",
    departure_date:"Departure Date and Time",
    document_request: "Document Request",
    document_request_cancel_statement:
        "Your Document Request Will Be Canceled! Do you confirm?",
    document_request_title: "Document Request Title",
    document_requests_title: "Document Requests Title",
    document_settings: "Document Settings",
    document_show: "Show Document",
    document_status: "Document Status",
    document_type: "Document Type",
    document_type_id: "ID",
    document_type_name: "Document Name",
    documents: "Documents",
    documents_are_required: "Documents are required!",
    documents_upload: "Upload Document",
    documents_university: "The university to which the documents belong",
    double_major_application: "Double Major Application",
    double_major_application_quotas: "Double Major Application Quotas",
    double_major_applications: "Double Major Applications",
    download: "Download",
    download_back:"Download Back",
    download_front:"Download Front",
    download_data: "Download Data",
    download_photos: "Download Photos",
    download_report: "Download Report",
    download_result_file: "Download Result File",
    draft_info: "Draft Information",
    drop_file: "Drop File Here",
    due_date: "Due Date",
    due_date_end: "Due Date End",
    due_date_start: "Due Date Start",
    dual_master_commission:"Dual Master Commission",
    duration: "Duration",
    dates_of_alleged_tncidents: "Date(s) of Alleged Incident(s)",
    date_signed: "Date Signed",
    description: "Description",
    delete_from_cart: "Delete from Cart",
    dso_registration_report: "DSO Registration Report",

    // E
    excused: "Excused",
    enrollment_information: "Enrollment Information",
    e_registration_system: "E-Registration System",
    early_discount_rate: "Early Discount Rate",
    ebys_send: "EBYS Send",
    ebys_send_statement:
        "The request will be sent to the EBYS system! Do you confirm the transaction?",
    ebys_type_id: "EBYS Type Document ID",
    ects: "ECTS",
    ects_credit: "ECTS Credit",
    ects_credit_of_uncompleted_slots: "ECTS Credit of Uncompleted Slots",
    ects_credits_attempted: "ECTS Credits Attempted",
    ects_credits_earned: "ECTS Credits Earned",
    edit: "Edit",
    edit_classrooms: "Edit Classrooms",
    edit_due_date: "Edit Due Date",
    edit_grade_conversion: "Edit Grade Conversiom",
    edit_option: "Edit Option",
    edit_payment: "Edit Payment",
    edit_preference: "Edit Preference",
    enrollment_agrement_form: "Bay Atlantic University" +
        "Enrollment Agreement Form",
    edit_question: "Edit Question",
    edit_section: "Edit Section",
    edit_slot: "Edit Slot",
    enrollment_report: "Enrollment Report",
    education: "Education",
    exam_report: "Exam Report",
    exam_report_all_section: "Exam Report (All Section)",
    exam_report_list: "Exam Report List",
    education_and_exam_info: "Education And Exam İnfo",
    education_and_exam_reqired: "Education And Exam Regired",
    education_credit: "Education Credit",
    education_delete_info:
        "You are about to delete the selected Education Information. Do you want to continue processing?",
    education_info: "Previous Education Information",
    education_information: "Previous Education Information",
    education_line_cancel: "Click to remove from Education Information.",
    education_status: "Education Status",
    educator_report: "Educator Report",
    effect_gpa: " Does it Effect the CGPA?",
    effect_ratio: "Effect Ratio",
    elective_pool: "Elective Pool",
    elective_pools_course_delete_confirm_text:
        "Elective Pools Course Delete Confirm_Text",
    elective_pools_delete_confirm_text: "Elective Pools Delete Confirm Text",
    elective_pools_form: "(Form for) Adding Elective Pool Courses ",
    elective_pools_info: "Elective Pool Information",
    elective_pools_report: "Elective Pools Report",
    elective_pools_store_confirm_text: "Elective Pools Store Confirm Text",
    elective_pools_title: "Elective Pool",
    elective_pools_update: "Elective Pools Update",
    email: "E-Mail",
    email_address: "E-Mail Address",
    email_of_advisor: "E-mail Of Advisor",
    email_placeholder: "Enter Your E-Mail Address",
    email_update: "Update E-Mail",
    email_verify_not: "Verify your E-Mail Address!",
    emergency_contact_txt: "Person to Contact in Emergency",
    emergency_person: "Emergency Contact Name",
    empty_classrooms: "Vacant Classrooms",
    empty_data_on_table: "Fill the empty fields in the Table!",
    end: "End",
    end_date: "End Date",
    end_time: "End Time",
    endorse: "Endorse",
    english: "English",
    enter_attendance: "Enter Attendance",
    enter_value: "Enter Value",
    entry_document: "Entry Document",
    entry_score: "Entry Score",
    entry_section: "Entry Section",
    epayment: "E-payment",
    epayments_report: "E-Payments Report",
    error: "Error",
    esl_course:"Esl Course",
    esl_instructor_sessions:"Esl Instructor Sessions",
    equal_weight_score: "Equally Weighted",
    equivalence_status: "Equivalence Status",
    evaluation: "Evaluation",
    evaluation_assessments:"Evaluation Assessments",
    evaluation_name: "Evaluation Name",
    evaluation_type: "Exam",
    event_owner: "Event Owner",
    event_type: "Event Type",
    exam: "Exam",
    except_roles:"Export Roles",
    exam_application_start_date: "Application Start Date for Exam",
    exam_application_to_date: "Application Deadline for Exam",
    emergency_person_phone: "Emergency Person Phone",
    exam_averages: "Exam Averages",
    exam_capacity: "Exam Capacity",
    exam_date: "Exam Date",
    enrollment_agreement_form: "Bay Atlantic University" +
        "Enrollment Agreement Form",
    enrollment_status: "Enrollment Status",
    exam_delete_info:
        "You are about to delete the selected exam Information. Do you want to continue processing?",
    exam_effect_ratio: "Exam Effect Ratio",
    enrollment_agrement_form_text: "This “Enrollment Agreement” is a contract between the student and the school. Therefore, Bay Atlantic University (BAU) recommends that students read and understand the terms of enrollment before completing and submitting this form." ,
    exam_info: "Exam İnfo",
    exam_line_cancel: "Click to remove from Exam Information.",
    exam_method: "Exam Method",
    exam_name: "Exam Name",
    exam_parts: "Parts of the Exam",
    exam_schedule1: "Exam Schedule",
    exam_schedule: "Exam Schedule",
    exam_schedule_id: "Exam Schedule ID",
    exam_schedule_format: "Course Code",
    exam_schedule_available_time:
        "Finding Available Time for the Exam Schedule",
    exam_score: "Exam Score",
    exam_status_report: "Exam_Status_Report",
    exam_type: "Exam Type",
    exam_time: "Exam Time",
    exams: "Exams",
    excel: "Excel",
    excel_file: "Excel File",
    excel_format: "Excel Format",
    excluded_program_codes: "Excluded Program Codes",
    excluded_registration_types: "Excluded Registration Types",
    excuse_file: "Excuse File",
    excuse_letter: "Excuse_Letter",
    expand_all: "Expand All",
    expiry_month: "Expiry_Month",
    expiry_year: "Expiry_Year",
    explanation: "Explanation",
    explanation_en: "Explanation En ",
    extend: "Extended",
    ie_reports:"ie-Reports",
    extra_installment: "Extra Installment",
    extra_time: "Extra Time",
    exam_overlap_report: "Exam Overlap Report",
    exception_logs:"Exception Logs",
    electrical: "Electrical",
    esl_attendance:"ESL Attendance",
    expense_amount:"Expense amount",
    expense_detail:"Expense detail",

    // F
    faculties: "Faculties",
    flight_code:"Flight Code",
    faculty: "School",
    faculty_grade_entry_report: "School Based Grade Entry Report",
    faculty_code: "School Code",
    faculty_code_name: "School Code / Name",
    faculty_name: "School Name",
    freshman: "Freshman",
    faculty_name_en: "School Name (EN)",
    faculty_of_choice: "School Of Choice",
    faculty_rank: "School Ranking",
    faculty_course_grade_entry_report: "Grade Entry Report",
    failed_data_count: "Failed Data Count",
    fall: "Fall",
    fall_and_spring: "Fall and Spring",
    fall_credit: "Fall Credit",
    false: "False",
    family: "Family",
    family_info: "Family Information",
    family_information: "Family Information",
    fast_transactions: "Fast Transactions",
    father: "Father",
    father_gsm: "Father's GMS",
    father_job: "Father's Job",
    father_name: "Father Name",
    father_name_placeholder: "Enter father's name",
    fathers_education: "Father's Educational Status",
    fathers_gsm: "Father's Gsm",
    fathers_job: "Father's Job",
    fall_2024: "Fall 2024",
    fees_and_installments: "Fees and Installments",
    female: "Female",
    fetch_document: "Fetch Document",
    field:"Field",
    fields_are_reqired: "Fill out all Fields marked (as) Required",
    first_name: "First Name",
    file: "File",
    file_name_course_schedule: "Course Schedule",
    file_name_dispatch_postponemen: "dispatch-postponemen",
    file_name_diploma: "Diploma File",
    file_name_diploma_annexes: "Diploma Annexes",

    file_name_english_placement_exam: "english-placement-exam",
    form_submission_successful:"Form Submission Successful ",
    form_submission_failed:"Form Submission Failed",
    file_name_exam_schedule: "exam_-chedule",
    file_name_language_proficiency: "language-proficiency",
    file_name_military_status: "military-status",
    file_name_new_password: "new-password",
    file_name_prep_transcript: "prep-transcript",
    file_name_school_certificate: "school-certificate",
    file_name_student_certificate: "student-certificate",
    file_name_student_information_system: "student-information-system",
    file_name_transcript: "transcript",
    file_name_user_guide: "user-guide",
    file_name_welcome_letter: "welcome-letter",
    file_upload_not_1: "Send English Proficiency Certificates to",
    file_upload_not_2: "Students who wish to freeze registration?",
    file_upload_not_3:
        "<a>ekayit@bau.edu.tr</a> adresinden iletişime geçebilirsiniz.",
    files: "Files",
    fill_at_least_one_field: "Fill at least one field",
    filter1: "Filter",
    filter: "Filter",
    filter_type: "Filter Type",
    filter_value: "Filter Value",
    filter_filter: "Filter",
    financial_report: "Financial Report",
    financial_penalties_part3: "Financial Penalties,Part III",
    financial_penalties_part3_checkbox: "I have read and I confirm the Financial Penalties Part III.",
    financial_penalties_part3_text: " 1. <strong>Late Fee</strong>: Failure to make any payment on or before the due date results in $25 per credit per\n" +
        " month. The late fee will apply only to the tuition. Other fees (for example, activity fee, technology\n" +
        " fee, etc.) will be deducted before applying payment for tuition<br><br>" +
        " 2.<strong>Return Check Fee:</strong>  A $35 fee will be charged for each unpaid check returned by the bank.\n" +
        " Moreover, BAU will recalculate the late fee until the balance is cleared<br><br>" +
        " 3.<strong>Special Cases</strong> : In the documented event of prolonged illness, accident, or death in the\n" +
        " immediate family (parents, siblings, children, or spouse) or other special circumstances that make\n" +
        " it impractical to complete the program, BAU will work toward a settlement that is reasonable and\n" +
        " fair to both parties",

    financial_penalties_part2: "Financial Penalties,Part II",
    financial_penalties_part2_checkbox: "I confirm that the information given in this form is true, complete and accurate",
    financial_penalties_part2_text: "If a student's financial obligation is not fulfilled, BAU is authorized to do the following until the money owed is paid:<br>" +
        "<br>" +
        "1) Students will not be able to register for the semester courses if they have not met their financial obligations. In addition, they will not be cleared for graduation, receive official transcripts or their diploma.<br>" +
        "2) Course grades for those students who have not completed their financial requirements until <span style='color: red'>the end (missing space)</span> of the final exam week will not be entered. This means the credits for these courses will not be earned until the payments are made.<br>" +
        "3) BAU withholds the release of the student's academic records or any information on the records.<br>" +
        "4) BAU withholds the issuance of the student's transcripts.<br>" +
        "5) If the student's account remains delinquent, BAU reserves the right to terminate enrollment<br>"
    ,

    financial_penalties_part1: "Financial Penalties Part I",
    financial_penalties_part1_checkbox: "I confirm that the information given in this form is true, complete and accurate  ",
    financial_penalties_part1_text:"Financial good standing and a university record clear of holds are required for students to receive services.<br>" +
        "Services, including but not limited to access to course, transcript issuance, diploma release, class registration (add, drop, withdrawal, etc.), and/or housing will not be provided to students with a financial balance due or a hold of any kind on their record. Holds are based on outstanding obligations and may be financial.<br>" +
        "Students in noncompliance with payment deadlines will result in the immediate suspension of services.<br>" +
        "Failure to meet financial obligations to the university will result in other collection procedures, which include account referral to credit reporting bureaus, private collection agencies, and DC Office of Tax and Revenue, and Office of Attorney General. Past due accounts are subject to garnishments, liens, and judgments and the withholding of money from tax refunds. In addition to late fees and interest, delinquent accounts will be assessed additional collection costs up to thirty percent of the past due balance, reasonable attorney fees, and other administrative costs. Once an account is referred to a collection agency payment must be made to the agency, not the University.<br>" +
        "No refund will be honored without the withdrawal request initiated on <span style='color:red'>MyBAU.</span> Applicants must also submit a Refund Request Form on <span style='color:red'>MyBAU</span> within 12 months of the initial application date<br>" ,
    financial_registration_by_Agency:"Financial Registration by Agency",
    final_letter_grade: "Final Letter Grade",
    finalize_the_application: "Finalize Application",
    finalize_grades: "Finalize Grades",
    finance_offset: "Finance Offset",
    finance_reports: "Finance Reports",
    financial_registration_report: "Financial Registration Report",
    find: "Find",
    find_student: "Find Student",
    finished_class: "Finished Class",
    first_installment: "First İnstallment",
    first_student_count: "Number of First-Time / New Students",
    force_education_price: "Force Education Price",
    foreign_document_number: "Foreign Document Number",
    foreign_document_type: "Foreign Document Type",
    foreign_status: "International Status",
    foreign_student: "International Student",
    foreign_student_credentials: "International Student Credentials",
    foreign_student_identity: "International Student ID",
    forgot_my_password: "I Forgot My Password",
    foreign_student_report:"Foreign student Report",
    friday: "Friday",
    from_score: "From Score",
    from_email: "From E-Mail",
    full_name: "Full Name",
    full_scholarship_students_enroll: "Full Scholarship Students Enroll",
    future_payment: "Postponing Payment Due Date",
    fykk_template: "Fykk_Template",
    furniture: "Furniture",
    floor: "Floor",
    fee: "Fee",
    financial_obligation:"Financial Obligation",
    // G
    gateway: "Gateway",
    gender: "Gender",
    general: "General",
    general_information: "General Information",
    gep_course_count: "Gep Course Count",
    gep_course_credit: "Gep Course Credit",
    gep_course_ects_credit: "Gep Course Ects Credit",
    get_report: "Get Report",
    give_exception_payment_approval: "Give Exceptional Payment Approval",
    giveable_roles: "Giveable Roles",
    gna_not_range: "CGPA Grade Range",
    gno: "CGPA",
    gno_simulation: "CGPA Simulation",
    gno_carculate: "Calculate CGPA",
    gpa: "GPA",
    gpa_control_report: "GPA Control Report",
    grade: "Grade",
    grade_conversion_define_tabel: "Grade Conversion Define Tabel",
    grade_conversion_define_title: "Grade Conversion Define Title",
    grade_conversion_id: "Grade Conversion Id",
    grade_conversion_info: "Grade Conversion Info",
    grade_conversion_name: "Grade Conversion Name",
    grade_conversion_range_end: "Grade Conversion Range End",
    grade_conversion_range_start: "Grade Conversion Range Start",
    grade_conversion_table_title: "Grade Conversion Table Title",
    grade_conversion_update_title: "Grade Conversion Update Title",
    grade_conversion_update_verify_text: "Grade Conversion Update Verify Text",
    grade_conversion_verify_text: "Grade Conversion Verify Text",
    grade_conversion_verify_text_2: "Grade Conversion Verify Text 2",
    grade_entry: "Grade Entry",
    grade_entry_confirmation_modal: "Clicking confirm will submit the final grades. After grades are submitted, only the registrar can make changes",
    grade_id: "Grade İd",
    grade_is_deleted_text: "Grade Is Deleted Text",
    grade_scala: "Grading Scale",
    grade_table_is_empty: "Grade Table Is Empty",
    grade_up_lessons: "Courses to Up Grade CGPA",
    grade_distribution_report: "Grade Distribution Report",
    grades: "Grades",
    grading_system: "Grading System",
    grading_system_id: "Grading System ID",
    graduate: "Graduate",
    graduate_application_after_verify_info:
        "Başvurunuzun herhangi bir sebeple yarım kalması halinde tarafınıza gönderilen SMS Kodu ile <a href=login>Başvuru Güncelleme</a> adresinden başvurunuza devam edebilirsiniz.",
    graduate_application_form: "Graduate Application Form",
    graduate_students_who_take_less_than_full_load_of_courses:
        "Graduate Students Who Take Less Than Full Load of Courses",
    graduate_application_form_update_btn:
        "Graduate Application Form Update Btn",
    graduate_application_yok_statement:
        "(Candidates who graduated from universities abroad can upload their YÖK Equivalency Certificates from the other documents section.)",
    graduate_applications: "Graduate Applications",
    graduate_date: "Graduate Date",
    graduate_semester: "Graduate Semester",
    graduate_detail_cpga: "Detailed CGPA",
    graduation_academic_year: "Graduation (Academic) Year",
    graduation_academic_year_semester: "Graduation Academic Year/Semester",
    graduation_average: "Graduation Average",
    graduation_date: "Graduation Date",
    graduation_date_between: "Graduation Date Range",
    graduation_decision_date: "Graduation Decision Date",
    graduation_decision_number: "Graduation Decision Number",
    graduation_degree: "Graduation Degree",
    graduation_information: "Graduation Information",
    graduation_semester: "Graduation Semester",
    graduation_status: "Graduation Status Check",
    graduation_university: "Graduation University",
    graduation_year: "Graduation Year",
    gross_price: "Gross",
    gsm_no: "Mobile Phone Number",
    gsm_no2: "US Mobile Phone Number",

    guarantee1: "Guarantee",
    guarantee1_address: "Guarantee Adress",
    guarantee1_national_id: "Identity Number of Guarantee",
    guarantee1_phone: "Guarantee Phone",
    guarantee2: "Guarantee",
    guarantee2_address: "Guarantee Adres",
    guarantee2_national_id: "Identity Number of Guarantee",
    guarantee2_phone: "Guarantee Phone",
    guarantee: "Guarantee",
    guarantee_address: " Address of Guarantee",
    guarantee_national_id: "Identity Number of Guarantee",
    guarantee_phone: "Kefil Phone",
    graduation_data_of_registrar: "Graduation Data Of Registrar",
    grading_scale_text: {
        system_0: "System 0 (Fail)",
        system_1: "System 1 (Pass)",
    },
    grade_entry_ip_info:
        "The decision to grant an in progress is at the discretion of the instructor as long as it is within the parameters of the In Progress Grade Policy. By submitting an IP grade for this student, I have read the In Progress Grade Policy and submitted an In Progress Grade Request Form to the Registrar.",
    grounds: "Grounds",
    goal: "Goal",

    // H
    holiday: "Holiday",
    have_student_program:
        "There is a student registration. Do not process here.",
    has_english_cert:
        "Will you apply for exemption from english preparatory program?",
    have_payment_approval: "Payment Approval",
    have_you_leave_of_absence_before: "Taken leave of absence before?",
    head_of_department: "Head of Department",
    head_of_program: "Coordinator",
    health: "Health",
    health_information: "Health Information",
    house_room_excel: "Excel Building Name,Excel Name,Excel Number Of Beds,Excel Floor,Excel Explanation",
    hello: "Hello",
    how_to_reset_my_bau_password:"How To Reset My Bau Password",
    help: "Help",
    help_videos: "Help Videos",
    hes_code: "HES Code",
    housing_bed_id:"Housing Bed Id",
    home_address: "Home Address",
    home_number: "Home Phone Number",
    housing_request_list:"Housing Request List",
    home_tel: "Home Phone Number",
    housing_request:"Housing Requests",
    horizontal_or_vertical_attended_university:
        "Lateral / Vertical Transfer from Previously Attended University",
    horizontal_or_vertical_attended_university_1:
        "Lateral / Vertical Transfer from Previously Attended University",
    horizontal_or_vertical_coming_from_university:
        "Lateral / Vertical Transfer to the University",
    horizontal_or_vertical_coming_from_university_1:
        "Lateral / Vertical Transfer to the University",
    hour: "Hour",
    hours: "Hours",
    hours_in_a_day: "Hours in a Day",
    housing_building: "Building",
    housing_agreement_form:"Housing Agreement Form",
    housing_airport_transfer_form:"Housing Airport Transfer Form",
    airport_transfer_form:"Airport Transfer Form",

    housing_building_name: "Building Name",
    housing_rooms: "Rooms",
    housing_tenant_form_success_message:"Thank you for choosing to stay at Island Condos Student Housing. Your reservation request has been received. When your request is approved, you will receive an email with a payment link. We would like to remind you that after you receive your confirmation e-mail, your security deposit payment must be completed to complete your reservation.",
    housing_reservation_form_success:"Housing Reservation Success",
    housing_reservation_form_success_message:"Your form has been successfully submitted and approved.",
    housing_reservation_form_30_days_error:"<strong>Thank you for your interest in Island Condos. In order to service your request for a stay of less than 30 days, please contact us at studenthousing@bau.edu for further assistance.</strong>",

    housing_reservations:"Housing Reservations",
    housing_tenant: "Tenant",
    housing_beds: "Beds",
    housing_beds_name: "Beds Name",
    housing_room_name: "Room Name",
    hybrid: "Hybrid",
    high_honor: "High Honor Process",
    high_honor_status: "Honor Status",
    high: "High",

    // I
    initial_enrollment_information: "Initial Enrollment Information",
    initial_enrollment_semester: "Initial Enrollment Semester",
    initial_complaint:"Initial Complaint",
    iban: "IBAN number",
    id: "ID",
    identity: "Identity",
    identity_information: "Identity Information",
    ie_reporting:"IE Reporting",
    image_upload: "Image Upload",
    import: "Import",
    import_button: "Import Button",
    import_course_schedule: "Import Course Schedule",
    import_draft: "Import Draft",
    import_exam_schedule: "Import Exam Schedule",
    import_photos: "Import Photos",
    in_class: "Face-to-Face / In Class",
    included_registration_types: "Included Registration Types",
    indefinite: "Indefinite",
    info_file: "Information File",
    info_link: "Information Link",
    info_text: "Info Text",
    information: "Information",
    input_type: "Input Type",
    installment: "Installment",
    installment_amount: "Installment Amount",
    installment_count: "Number of Installments",
    installment_options: "Installment Options",
    installment_payment: "Installment Payment",
    installment_payment_type: "Installment Payment Type",
    installment_start_date: "Installment Start Date",
    installments: "Installments",
    installments_total: "Sum of Total Installments",
    instructor: "Instructor",
    instructor_can_use: "Can be used by Instrutors",
    instructor_courses_title: "Instructor Courses Title",
    instructor_name: "Name of Instructor",
    instructor_surname: "Surname of Instructor",
    instructors: "Instructors",
    instructor_letter_grade_excel_export:
        "Instructor Letter Grade Excel Report",
    intership_information: "Intership Information",
    intership_use_only: "Intership use only",
    invalid_course_coordinator: "Course Coordinator not found",
    invalid_student: "Student not found",
    invalid_value: "Invalid Value",
    invalid_national_id: "Invalid national id",
    invert: "Invert",
    investigation_report: "Investigation Report",
    invitation_to_the_defense: "Invitation To The Defense",
    invoice1: "Invoice 1",
    invoice2: "Invoice 2",
    invoice3: "Invoice 3",
    invoice4: "Invoice 4",
    invoices: "Invoices",
    irregular: "Irregular",
    irregular_status: "Irregular Status",
    is_before_undergraduate_transfer:
        "Have you made a horizontal transfer before?",
    is_before_undergraduate_transfer_type:
        "What type of horizontal transition did you do?",
    is_default: "Is it default?",
    is_graduate: "Is it graduate?",
    is_numeric: "Is it numeric?",
    is_paid: "Has it been Paid?",
    is_passing: "Is Passing",
    incident_details:"Incident details",

    incident_location:"Incident Location",
    is_rate: "Is it Rate",
    is_required: "Is it Required?",
    is_revert: "Is it revert ?",
    is_there_a_header: "Is there a Header/Heading",
    is_take_exam: "Is Take Exam",
    is_exam_time_lesson: "Is Exam Time Lesson",
    is_additional_classroom: "Is Additional Classroom",
    initial_complaint_form: "Initial Complaint Form",
    incident_details_initial_complaint_form: "Describe the incident(s) as clearly as possible, including such things as: what force or physical contact, if any, was used, any verbal statements such as threats, requests, demands, etc., what response(s) did you give; attach additional pages if more space is needed",
    island_condo_checklist: "Island Condo Move-In and Move-Out Checklist",
    island_condo_checklist_text: "Fill out this checklist before moving in and after a tenant moves out of the property. Note that any damage and maintenance issues require to be resolved by Island Condo or authorized agency on the property's conditions. ",
    inspection: "Inspection",
    inspection_agree_text: "(tenant), understand that unless otherwise noted, all damages are under the tenant's responsibility and will be deducted from deposit upon move-out date.",
    island_condo_officer: "Island Condo Officer",
    // J
    job: "Job",
    job_address: "Job Address",
    job_and_contact_info: "Job Contact İnfo",
    job_corporation: "Job Corporation",
    job_department: "Job department",
    job_experience: "Job experience",
    job_experience_desc:
        "(Varsa önceki iş deneyiminiz ile birlikte toplam süre olarak belirtiniz.)",
    job_info: "Job İnfo ",
    job_other: "Job Other",
    job_sector: "Job Sector",
    job_tel: "Job Phone",
    jobs: "Jobs",
    joining_place: "Joining Place",
    junior: "Junior",
    // K
    key: "Key",
    kvkk_content: "KVKK Content",
    key_name: "Key Name",

    // L
    last_name: "Last Name",
    last_course_credit: "Last Course Credit",
    lab: "Lab",
    lab_credit: "Laboratory  (Credits)",
    lab_section: "Lab Section",
    language: "Language",
    language_exam_score: "Language Exam Score",
    language_proficiency_certificate: "Language Proficiency Certificate",
    last_approve_date: "Last Approve Date",
    last_course: "Course Previously Taken",
    last_course_registration_semester: "Last Course Registration Semester",
    last_course_registration_year: "Last Course Registration Year",
    last_education_status: "Education Status",
    last_lesson_registration_year: "Last Lesson Registration Year",
    last_process_date: "Last Process Date",
    last_school: "Last School",
    last_school_date_of_graduation: "Last School Date Of Graduation",
    last_school_diploma_number: "Last School Diploma Number",
    last_school_diploma_point: "Last School Diploma Point",
    last_school_registration_date: "Last School Registration Date",
    last_university: "Last University",
    last_update_date: "Last Day for Applications",
    leave_absences: "Leave of Absence",
    leave_of_absence: "Leave Of Absence",
    leave_of_absence_count: "Number of Semesters with Leave of Absence ",
    list_of_Students_in_the_selected_academic_year:
        "List of Students in the Selected Academic Year",
    leave_of_absence_fall: "Leave of Absence  in Fall Semester",
    leave_of_absence_period: "Leave Of Absence Period",
    leave_of_absence_reason: "Reason for Leave of Absence",
    leave_of_absence_semesters: "  Leave Of Absence Semesters",
    leave_of_absence_settings_title: "Leave of Absence Settings",
    leave_of_absence_spring: "Leave Of Absence Spring",
    leave_of_absence_title: "Leave of Absence Request Form",
    leave_of_absence_verify_text: "Leave Of Absence Verify Text",
    leave_of_absence_reason_medical: "Severe medical condition of the student or a close relative.",
    leave_of_absence_reason_financial: "Financial hardship (domestic students only).",
    leave_of_absence_reason_death: "The death of an immediate family member.",
    leave_of_absence_acknowledgment: "I have read and understood the academic catalog and the terms and rules of Leave of Absence Policy.\n" +
        "                                    I acknowledge that I am expected to return to school on the first day of class of the semester\n" +
        "                                    immediately following the leave of absence. If I fail to return within the first two weeks of class,\n" +
        "                                    I will be subject to an administrative withdrawal from the University.",
    leave_of_absence_verify_text_graduate:
        "Leave Of Absence Verify Text for Graduate",
    leave_of_absence_explanation:
        "Please, provide explanation for Leave of Absence:",
    bau_inspiration_text: "Be an Innovator, <br/> Be a Leader, <br/> <span class=\"font-weight-bold text-warning\">At BAU!</span>",
    leave_with_consent: "Leave With Consent",

    legal_presence_type: "Legal Presence Type",

    leave_with_consent_form: "Leave With Consent Form",
    leave_with_consent_reason: "Leave With Consent Reason",
    leave_with_consent_statement1: "Leave With Consent Statement",
    leave_with_consent_statement1_content: "????",
    line_number: "{number}. line: ",

    leave_of_absence_statement:
        "Do you want to close the form with the selected interview result?",
    leave_with_consent_verify_text: "Leave With Consent Verify Text",
    leaving_academic_year: "Leaving (Academic) Year",
    leaving_academic_year_semester: "Leaving Academic Year/Semester",
    leaving_date: "Leaving Date",
    leaving_date_between: "Leaving Date Between",
    leaving_information: "Leaving Information",
    leaving_semester: "Leaving Semester",
    lecturer_report: "Lecturer Report",
    lesson_pool: "Elective Course Pool",
    lesson_time: "Class Hours",
    lesson_timetable: "Course Schedule",
    lessons_only_and_delete_students: "Close Courses and Delete Students",
    lessons_only_and_delete_students_and_sms_send:
        "Close Courses Delete Students and Send SMS",
    lessons_we_have_not_taken_before:
        "Courses (You Have ) Not Taken Previously",
    lessons_you_have_taken: "Courses (You Have) Not Completed Successfully",
    letter_grade: "Letter Grade",
    level: "Level",
    level_id: "Level ID",
    level_of_study: "Level Of Study",
    list: "List",
    list_of_student_taking_the_course: "List of Students Taking the Course",
    loading: "Loading",
    log_detail: "Log File Details",
    log_id: "Log_İd",
    log_message: "Log File Message",
    log_name: "Log File Name",
    log_status: "Log Status",
    log_time: "Log Time",
    log_type: "Log Type",
    login: "Login",
    logout: "Logout",
    length_of_hold:"Length of Hold",
    list_any_witness_names: "List any witness name(s)",
    list_where_the_incidents_occurred: "List Where the Incident(s) Occurred",
    location: "Location",
    low: "Low",
    latin_honors: "Latin Honors",
    learning_outcomes: "Learning Outcomes",

    // M
    modality: "Modality",
    mail_order: "Mail Order",
    major_type: "Major Type",
    master_commission:"Master Commission",
    major_types: "Types of Major",
    make_payment: "Make Payment",
    make_payment_success_message:"Verification code has been sent to your e-mail address",
    midterm: "Midterm Exam",
    final: "Final Exam",
    makeup: "MakeUp Exam",
    make_up_exam: "MakeUp Exams",
    make_up_exam_entry: "MakeUp Exam Enrty",
    male: "Male",
    mass_course_closure: "Mass Course Closure",
    max_capacity: "Max Capacity",
    max_duration: "Maximum Duration",
    medicine_group_code: "Medicine Group Code",
    medicine_groups_title: "School of Medicine Exhange Student Groups",
    medicinegroup: "Medical Groups",
    medicines: "Medicine Taken Regularly if any",
    meeting_duration: "Duration of Meeting",
    meeting_number: "Meeting Number",
    meeting_place: "Meeting Place",
    meetings: "Meetings",
    member_type: "Member Type",
    menu: "Menu",
    menu_items: "Menu Items",
    message: "Message",
    message_semester: "Message Semester",
    merge_section_deleted: "Merge Section Deleted",
    midterm_and_final_data_collection: "Midterm And Final Data Collection",
    military: "Military Service",
    military_branch: "Military Service Branch Office",
    military_branch_number: "Military Service Branch Office Number",
    military_delay_date: "Military Service Postponement Date",
    military_information: "Military Service Information",
    military_office: "Military Service Recruiting Office",
    military_service_city: "City Code / Name",
    military_service_city_code: " City Code",
    military_status: "Military Service Status",
    middle_name:"Middle Name",
    military_status_document: "Military Service Status Document",
    min_pass_count: "Minimum Pass Count",
    min_point: "Minimum Score",
    min_rank: "Minimum Rank",
    minor_application: "Minor Application",
    minor_application_quotas: "Minor Application Quotas",
    minor_applications: "Minor Applications",
    minor_programs: "",
    minor_program: "Minor Program",
    minor_certificate: "Minor Certificate",
    minute: "Minute",
    missing_document: "Missing Document",
    missing_documents: "Missing Documents",
    missing_documents_report: "Missing Documents Report",
    mobile_number: "Mobile Phone Number",
    mobile_number_update: "Mobile Number Update",
    mobile_placeholder: "Enter Mobile Phone",
    mobile_verify_not: "Please Verify Mobile Number",
    mode_of_study: "Mode of Study",
    model :"Model",
    module: "Module",
    module_id: "Module (ID)",
    monday: "Monday",
    month: "Month",
    mother: "Mother",
    mother_gsm: "Mother's GSM",
    mother_job: "Mother's Job",
    mother_name: "Mother Name",
    mothers_education: "Mother's Educational Status",
    mothers_gsm: "Mother's Gsm",
    mothers_job: "Mother's Job",
    move: "Move",
    move_here: "Move Here",
    move_payment: "Move Payment",
    move_payment_plan: "Move Payment Plan",
    multiple_choice: "Multiple Choice",
    multiselect_deselect: "Press enter to remove",
    multiselect_select: "Press enter to select",
    multiselect_selected: "Selected",
    my_course_schedule: "My Course Schedule",
    my_jobs: "My Jobs",
    my_make_up_exams: "My MakeUp Exams",
    my_requests: "My Requests",
    my_room_reservations: 'My Room Reservations',
    mobile_tel_country_code: "Mobile Tel Country Code",
    my_courses: "My Courses",
    my_invoices: "My Invoices",
    move_in: "Move-In",
    move_out: "Move-Out",
    move_in_inspection: "Move-In Inspection",
    move_out_inspection: "Move-Out Inspection",
    will_take_make_up_exams: "Will Take MakeUp Exams",
    medium: "Medium",
    monthly_fee: "Monthly Fee",

    // N
    not_enrolled_yet: "Not Enrolled Yet",
    n_options_selected: "{n} options selected",
    notice_to_student: "Notice To Student",
    notice_to_student_checkbox: "I confirm that the information given in this form is true, complete and accurate ",
    notice_to_student_text: " a. This agreement is a legally binding document.<br><br>" +
        " b. You have accepted all the previous information by electronically signing up to this stage.<br><br>" +
        " c. You are entitled to an exact copy of this agreement and any disclosure pages you electronically" +
        " sign.<br><br>" +
        " d. This agreement and the academic catalog posted on the website of BAU constitute the entire\n" +
        " agreement between the student and BAU.<br><br>" +
        " e. BAU reserves the right to reschedule the program start date when the number of students\n" +
        " scheduled is too small.<br><br>" +
        " f. BAU reserves the right to terminate a student's enrollment for unsatisfactory progress," +
        " nonpayment of tuition, or failure to abide by established standards of conduct.\n<br><br>" +
        " g. BAU does not guarantee the transferability of credits to a college, university, or institution. Any\n" +
        " decision on the comparability, appropriateness, and applicability of credit and whether they should\n" +
        " be accepted is the decision of the receiving institution.<br><br>" +
        " h. BAU reserves the right to make changes to policies. When doing so, BAU makes announcements\n" +
        " to students via email, website updates, and/or posters on the campus. It is the responsibility of the" +
        " students to check their emails, the website, and the Academic Catalog",

    name: "Name",
    name2: "Name",
    not_sent: "Not Sent",
    name_en: "Name (EN)",
    name_placeholder: "Enter Your Name",
    name_surname: "First Last Name",
    national_credit: "National Credit",
    national_credit_of_uncompleted_slots:
        "National Credit of Uncompleted Slots",
    national_id: "National ID",
    national_id_passport_no: "National ID",
    national_id_placeholder: "Enter Your National ID",
    national_number: "ID Number",
    nationality: "Nationality",
    nationality_option: "Nationality Option",
    necessity: "Necessity",
    neighborhood: "Neighborhood",
    new: "New",
    new_draft: "New Draft",
    new_exam: "New Exam",
    new_option: "New Option",
    new_password: "New Password",
    new_password_repeat: "Repeat- New Password",
    new_payment_collection: "New Payment Collection",
    new_request: "New Request",
    new_question: "New Question",
    new_register: "New Student Registration",
    new_register_update: "Update Student Registration",
    new_section: "New Section",
    new_status_radio: "Assign New Status ",
    new_students: "New Students",
    new_window: "New Window",
    next: "Next",
    no: "No",
    no_lessons_to_be_added: "No Lessons To Be Added",
    no_course: "No Course",
    no_grades_entered: "Grades Not Entered",
    no_installment_message: "No Installment Message",
    no_options: "Option not found.",
    no_payment: "Please Make the Payment!",
    no_result: "Not Found",
    no_result_1: "Not Found",
    no_result_2: "Record not found",
    no_info_found: "No Information Found",
    no_course_selection_has_been_made:
        "No course selection has been made",
    non_dep_course_count: "Non Dep Course Count",
    non_dep_course_credit: "Non Dep Course Credit",
    non_dep_course_ects_credit: "Non Dep Course Ects Credit",
    none: "None",
    none_of_students: "None of the Students",
    not_allow_attendance_updates: "Not allow attendence Updates",
    not_assigned: "Not Assigned",
    not_have_payment_approval: "Payment Not Approved",
    not_include_calculation:
        "Number of Exams not included in (GPA) Calculations",
    not_required: "Not Required",
    name_of_persons:"Name of Persons",
    not_pending:"Not Sent",
    not_taken: "Not Taken",
    not_uploaded: "Yüklenmedi",
    number_of_unexcused_absence_hours: "Number of Unexcused Absence Hours",
    not_selected: "Not Selected",
    note: "Note",
    note_for_document: "Note",
    note_you_want_to_add: "Note you want to add",
    notes: "Notes",
    notexcredit: "Grade x Credit",
    note_area: "Not Area",
    now_job: "Now Job",
    number: "Number",
    number_of_courses_taken: "Number of Courses Taken",
    number_of_beds: "Number Of Beds ",
    number_of_done_exams: "Number of Exams Taken",
    number_of_exams: "Number of Exams",
    number_of_installments: "Number of Installments",
    number_of_leave_of_absence:
        "Number of Semesters Leave of Absence was taken",
    number_of_observers: "Number of Exam Observers / Invigilators",
    number_of_promissory: "Number of Promissory Notes",
    number_of_records_updated: "Number of Records Updated",
    number_of_registered_courses: "Number of Registered Courses",
    number_of_remaining_semesters: "Number of Remaining Semesters",
    number_of_semesters:
        "Number of Semesters the student was enrolled in the program",
    number_of_semesters_studied: "Number of Semesters Studied",
    number_of_students_report: "Number of Students Report",
    number_of_students: "Number of Students",
    number_of_students_by_status_report: "Number of Students Report by Status",
    number_of_courses_enrolled_by_student: "Number of Courses Enrolled by Student",
    number_of_courses_that_each_student_is_enrolled_by_year_semester:
        "Number of Courses that Each Student is Enrolled by Year/Semester",
    numerical_score: "Numerical Score",
    number_of_students_that_must_take_the_core_courses:
        "Number of Students That Must Take the Core Courses",
    new_advisor_staff: "New Advisor Staff",
    number_of_student: "Number Of Student",
    new_housing_reservation: "New Housing Reservation",

    // O
    observers: " Exam Observers",
    oauth_confirmation_text: "{client} wants to login with your UMIS account.",
    auto_course_registration: "Auto Course Registration",
    auto_repeat_course_registration: "Auto Repeat Course Registration",
    auto_irregular_course_registration: "Auto Irregular Course Registration",
    office: "Office",
    offset_amount: "Offset_Amount",
    offset_date: "Offset Date",
    offset_total: "Offset Total",
    offsets_detail: "Offsets Detail",
    ok: "Ok",
    old_name: "Old Name",
    old_name_if_exists: "Old Name if Any",
    ombudsman: "Ombudsman",
    one_time_payment: "One Time Payment",
    online: "Online",
    online_credit_card: "Online Credit Card",
    online_reg_not_1: "Applied only to students placed with OSYM Exam score.",
    online_reg_not_2: "In case of a technical problem during e-registration",
    online_reg_not_3: "contact via e-mail",
    online_reg_system: "Online Registration System",
    open_parameter: "Properties of Parameters",
    open: "Open",
    operation: "Operation",
    option: "Option",
    order: "Order",
    order_of_preference: "Order of Preference",
    osymDocCode: "????",
    osymExamCode: "????",
    osym_address_information: "OSYM Address Information",
    osym_code: "OSYM Code",
    osym_information: "OSYM Information",
    osym_placement_document: "OSYM Placement Document",
    osym_result_document: "OSYM Result Document",
    osym_scholarship: "OSYM Scholarship",
    osym_score_information: "OSYM Score Information",
    osymquota: "OSYM Quota",
    other: "Other",
    other_phone: "Other Phone",
    other_identies: "Other Identities",
    other_information: "Other Information",
    other_payments: "Other Payments",
    other_programs: "Other Programs",
    other_university: "Other Universty",
    outputs: "Outputs",

    // Q
    question: "Question",
    questioning: "Questioning",
    questions: "Questions",
    quota: "Quota",
    quota_management: "Quota Management",
    quota_type: "Quota Type",

    // P
    printed: "Printed",
    not_printed: "Not Printed",
    proficiency_exams: "Proficiency Exams",
    page: "Page",
    page_not_found: "Page Not Found!",
    paid: "Paid",
    paid_amount: "Paid Amount",
    parameter: "Parameter",
    parameter_id: "Parameter Id",
    parameter_items: "Parameter Items",
    parent_id: "Parent Id",
    parts: "Parts",
    part_of_the_day: "Part of the Day",
    passive: "Passive",
    passport_country: "Passport Country",
    passport: "Passport",
    passport_number: "Passport Number",
    password: "Password",
    password_contains_eight_characters_message: "Password Contains Eight Characters",
    password_contains_number_message: "Password Contains Number",
    password_contains_uppercase_message: "Password Contains Uppercase",
    password_contains_lowercase_message: "Password Contains Lowercase",
    password_contains_special_character_message: "Password Contains Special Character",
    password_equals_message: "Password Equals",
    path_name:"Path Name",
    pay: "Pay",
    pay_type: "Payment Type",
    pay_with_credit_card: "Pay With Credit Card",
    pay_with_paypal: "Pay With PayPal",
    payer: "Payer",
    payer_address: "Address of Payer",
    payer_national_id: "Identity Number of Payer",
    payer_phone: "Payer Phone",
    payment: "Payment",
    payment_amount: "Payment Amount",
    payment_approval: "Payment Approval",
    payment_approval_exceptions: "Payment Approval Exceptions",
    payment_approval_failed: "Payment Approval Failed",
    payment_approval_successful: "Payment Approval Successful",
    payment_approvals: "Payment Approvals",
    payment_date: "Date of Payment",
    payment_date_start: "Payment Date Start",
    payment_date_end: "Payment Date End",
    payment_info: "Payment Information",
    payment_method: "Payment Method",
    payment_plan: "Payment Plan",
    payment_plan_create_btn: "Create Payment Plan",
    payment_plan_title: "Payments Created ",
    payment_plan_types: "State of the Student",
    payment_plans: "Payments Created",
    payment_plans_report: "Payments Created",
    payment_scholarship_information: "Payment / Scholarship Information",
    payment_status: "Payment Status",
    payment_status_E: "Yes",
    payment_status_H: "No",
    payment_total: "Amount to be Paid",
    payment_type: "Payment Method",
    payment_types: "Payment Methods",
    payment_type_group: "Payment Type Group",
    payment_options: "Payment Options",
    payments_saved: "Payments Saved",
    payments_report: "Payments Received",
    pending: "Pending",
    period: "Academic Year-Semester",
    period_code: "Period Code",
    period_id: "Academic Year-Semester",
    period_status: "Period/Semester Status",
    period_x: "(Fall / Spring) Semester",
    periods: "Semesters",
    period_number: "Period Number",
    permission: "Permission",
    process_start: "process start",
    process_select: "Select Process",
    permission_id: "Process (id)",
    permission_information: "Permission Information",
    personal_email: "Personal E-Mail",
    personal_info: "Personal Information",
    personal_information: "Personal Information",
    ph_programs_info: "(*) For Doctoral Programs.",
    photo: "Photo",
    pilotage_collection_report: "Pilotage Collection Report",
    pilotage_invoices: "Pilotage Invoices",
    pin_code: "Pin Code",
    place_added: "Place Added",
    placement_exam: "Placement ",
    placement_exam_classroom_delete_confirm_text:
        "Placement Exam Classroom Delete Confirm Text",
    placement_exam_classroom_store_confirm_text:
        "Placement Exam Classroom Store Confirm Text",
    placement_exam_classrooms_define: "Placement Exam Classrooms Define",
    placement_exam_classrooms_info: "Placement Exam Classrooms Info",
    placement_exam_classrooms_ranking: "Placement Exam Classrooms Ranking",
    placement_exam_classrooms_ranking_success:
        "Placement Exam Classrooms Ranking Success",
    placement_exam_classrooms_title: "Placement Exam Classrooms",
    placement_exam_part_classroom_delete_confirm_text:
        "Placement Exam Part Classroom Delete Confirm Text",
    placement_exam_part_classroom_store_confirm_text:
        "Placement Exam Part Classroom Store Confirm Text",
    placement_exam_part_classrooms_define:
        " Classroom Assignment for Placement Exam Part",
    placement_exam_part_classrooms_ranking:
        "Classroom Rankings for Placement Exam Part",
    placement_exam_part_classrooms_ranking_success:
        "Placement Exam Part Classrooms Ranking Success",
    placement_exam_part_classrooms_title:
        "Placement_Exam_Part_Classrooms_Title",
    placement_exam_part_classrooms_update:
        "Placement Exam Part Classrooms Update",
    placement_exam_part_classrooms_update_info:
        "Placement Exam Part Classrooms Update Info",
    placement_exam_score_upload_info: "Placement Exam Score Upload Info",
    placement_exam_score_upload_title: "Placement Exam Score Upload Title",
    placement_exams: "Placement  Exams",
    placement_rank: "Placement Ranking",
    placement_student: "Placement Student",
    placement_score: "Placement_Score",
    placement_success_order: "Placement with respect to Success Rankings",
    please_try_again: "Please Try Again",
    point: "Grade Weight",
    point_type: "Type of Score",
    painting: "Painting",
    pest_control: "Pest Control",
    plumbing: "Plumbing",

    points: "Points",
    pool: "Pool",
    pool_name: "Pool Name",
    pool_name_en: "Pool Name (EN)",
    pool_type: "Pool Type",
    postal_code: "Postal Code",
    practical: "Practical",
    practical_credit: "Practical Credit",
    precondition: "Prerequisite",
    preference_information: "Preference Information",
    preference_procedures: "Preference Procedures",
    preferences: "Preference",
    preferred_program: "Preferred Program",
    prefers: "Prefers",
    presidents_letter:"Presidents Letter",
    preliminary_information_form: "Preliminary Information Form",
    prep_academic_year: "Academic Year of the English Prep Program",
    prep_attendance: "Attendance Entry",
    prep_attendance_confirmation_modal: "Attendance Confirmation Modal",
    prep_attendance_publish_modal: "Attendance Publish Modal",
    prep_classes_define_form: "Prep Classes Define Form",
    prep_classes_delete_confirm_text: "Prep Classes Delete Confirm Text",
    prep_classes_store_confirm_text: "Are you sure you want to complete student registration?",
    prep_classes_title: "Prep Classes",
    prep_classes_update_confirm_text:
        "You are about to update the Prep Class registration, do you confirm the action??",
    prep_classes_update_form: "Prep Classes Update Form",
    prep_classes_update_info: "Prep Classes Information",
    prep_exam_confirmation_modal: "Prep Exam Confirmation Modal",
    prep_exam_definitions: "Prep Exam Definitions",
    prep_exam_publish_modal: "Prep Exam Publish Modal",
    prep_grade_entry: "Prep Program Grade Entry",
    prerequisites_text:"Prerequisites Course",
    prep_levels: "Prep Levels",
    prep_modules: "Prep Program Modules",
    prep_reports: "Prep Program Reports",
    prep_schedule: "Prep Program Schedule",
    prep_schedule_draft_delete_confirm_text:
        "Prep_Schedule_Draft_Delete_Confirm_Text",
    prep_schedule_draft_store_confirm_text:
        "Prep_Schedule_Draft_Store_Confirm_Text",
    prep_schedule_draft_title: "Prep Program Schedule Drafts",
    prep_schedule_import_data_excel_format:
        "Classroom Name, Level, Class, Day, Start Time, End Time, Start Time, End Time, Start Time, End Time, Start Time, End Time, Start Time, End Time, Instructor ID, Instructor Overlap, Class Overlap",
    prep_school: "Prep School",
    prep_school_approval: "Prep School Approval?",
    prep_status: "Prep Program Status",
    prep_students: "Prep Students",
    prep_students_import_excel_format: "Student Number, Level, Class(Section)",
    prep_attendance_status: "Attendance Status",
    prep_exam_status: "Prep Exam Status",
    preparation_note_entry: "Preparation Note Entry",
    preparatory_information: "Preparatory Information",
    preparatory_notes: "Preparatory Notes",
    prerequisites: "Prerequisites",
    present: "Present",
    preview: "Preview",
    preview_btn_info:
        "Click the save button below to complete the application!",
    preview_save_btn_info:
        "You can save your application without uploading your documents. After saving you will get a follow link. You can upload your documents by logging in from the link below.",
    price: "Fee",
    price_type: "Price Type",
    priceless: "Priceless",
    prices: "Fees",
    print: "Print",
    print_front: "Print Front",
    print_back: "Print Back",
    print_mail_order_form: "Print Mail Order Form",
    print_promissory_note: "Print Promissory Note",
    print_receipt: "Print Receipt",
    print_roster: "Attendance List",
    print_weekly_roste: "Weekly Attendance List",
    proccess_success: "Proccess Successful",
    proccess_will_be_started:
        "The proccess will be started. Do you confirm the Procees? ",
    process: "Process",
    process_type: "Process Type",
    processes: "Processes",
    processing: "Processing",
    processing_information: "Information Processing",
    processing_model: "Information Processing Model",
    product: "Product",
    president_list_report:"President List Report",
    product_code: "Product Code",
    products: "Products",
    profession_job: "Profession / Job",
    proficiency_certificate_type: "Proficiency Certificate Type",
    proficiency_document: "Proficiency Certificate",
    proficiency_exam: "Proficiency ",
    proficiency_requests: "English Proficiency Requests",
    proficiency_status: "Proficieny Status",
    proforma_invoice: "Proforma Invoice",
    proforma_invoices: "Proforma Invoices",
    program: "Program",
    program_code: "Program Code",
    program_code_name: "Program Code / Name",
    program_codes: "Program Codes",
    program_coordinator: "Program Coordinator",
    program_days: "Program Days",
    program_decrease: "Click to remove from selected programs.",
    program_delete_info:
        "You are about to delete the selected Program. Do you want to continue processing?",
    program_info: "Program Information",
    program_information: "Select Program",
    program_language: "Language of Instruction for the Program",
    program_level: "Program Level",
    program_level_1: "Education Level",
    program_level_d: "Doctorate",
    program_level_l: "Undergraduate Degree Program",
    program_level_ol: "Associate Degree Program",
    program_level_yl: "Master's Degree Program",
    program_name: "Program Name",
    program_name_en: "Program Name (EN)",
    program_select: "Program Select",
    program_select_min: "Please choose at least one program!",
    program_selected: "The program has already been selected.",
    programs: "Programs",
    programs_applied: "Programs Applied",
    programs_selected: "Programs Selected",
    programs_to_choose: "Programs to Choose",
    programs_you_are_applying: "Programs You Are Applying",
    program_groups: "Program Groups",
    program_of_study: "Program of Study",
    project_advisory: "Project Advisory",
    promissory_no: "Promissory No",
    promissory_note: "Promissory Note",
    promissory_payment: "Promissory Payment",
    provision: "Provision",
    public_scholarship: "Public Scholarship",
    publish: "Publish",
    publish_at: "Publish At",
    published: "Published",
    punishment: "Punishment",
    punishment_number: "Punishment Number",
    punishment_end_date: "Punishment End Date",
    punishment_start_date: "Punishment Start Date",
    product_prices_import_format:
        "School Code, Program Code, Registration Academic Year, Student Group, Price, Currency, Due Date, Number of Installments, Start Date, End Date",
    passing_grade: "Passing Grade",
    // R
    refered_student: "Refered Student",
    referal_amount: "Referal Amount",
    referal: "Referal",
    random: "Random",
    registrar_report: "Registrar Report",
    rank: "Rank",
    ranking: "Ranking",
    read_and_accept: "I have read and I approve",
    reason: "Reason",
    reason_for_leaving: "Reason for Leaving",
    reason_for_request: "Reason for Request",
    reason_for_return: "Refund Reason",
    reason_for_withdrawing: "Please Indicate the Reason for Withdrawing",
    reason_to_increase_max_duration: "REASON TO INCREASE MAX TIME",
    reason_for_denial: "Reason for Denial",
    receipt_number: "Receipt Number",
    receipt_number_format: "Receipt Number Format",
    receipt_number_not_created: "Failed to generate receipt number",
    receipt_search: "Receipt_Search",
    receipts: "Receipts",
    requester: "Requester",
    recurring: "Recurring",
    refund: "Refund",
    room_name:"Room Name",
    room_reservation_system: "Room Reservation System",
    room_reservation_form: "Bay Atlantic University" + ' ' + "Room Reservation Form",
    refund_amount: "Refund_Amount",
    refund_date: "Refund Date",
    refund_form: "Refund Form",
    refund_reason: "Reason for Refund",
    refund_request: "Refund Request",
    refund_request_list: "Refund Request List",
    refund_type: "Types of Refund",
    registration: "Registration",
    registration_academic_year: "Academic Year of Registration",
    registration_data: "Registration Data",
    registration_date: "Registration Date",
    registration_date_between: "Registration Date Range",
    registration_delete_text:
        "You are about to delete the record. Do you approve the transaction?",
    registration_document_types: "Registration Document Types",
    registration_guide: "Registration Guide",
    registration_hour: "Registration Hour",
    registration_information: "Registration Information",
    registration_number: "Registration Number",
    registration_season: "Registration Semester",
    registration_semester: "Registration Period",
    registration_statement: "Registration Statement",
    registration_status: "Registration Completed",
    registration_type: "Registration Type",
    registration_type_1: "Registration Type",
    registration_type_registration: "Registration",
    registration_type_student: "Student",
    registration_types: "Registration Types",
    relationship_to_student: "Relationship To Student",
    registration_year: "Year of Registration",
    registrations: "Registrations",
    rejected: "Rejected",
    remaining_amount: "Remaining Amount",
    remaining_credit: "Remaining Credits",
    remaining_payment_amount: "Remaining Payment Amount",
    remaining_quota: "Remaining Quota",
    remaining_students_radio: "Remaining Students",
    remove_exception_payment_approval: "Remove Exception Payment Approval",
    remind_password: "Password Reminder",
    remove_approve: "Remove Approve",
    repeat: "Repeat",
    role_name:"Role Name",
    role_filter:"Role Filter",
    repeat_count: "Repeat Count",
    repeat_student_count: "Number of Repeat Students",
    repeat_type: "Repeat Type",
    repetition_status: "Repetition Status",
    report: "Report",
    report_type: "Report Type",
    race:"Race",
    reporter_analysis_report: "Reporter Analysis Report",
    reporter_assignment_letter: "Reporter Assignment Letter",
    request_data_count: "Request_Data_Count",
    request_date: "Request Date",
    request_information: "Request Information",
    request_number: "Request Number",
    request_reason: "Request Reason",
    request_type: "Request Type",
    requests_info: "Requests",
    request_form:"Request Form",

    requests_of_leave_with_consent: "Requests Of Leave With Consent",
    required: "Required",
    required_course_count: "Required Course Count",
    required_course_credit: "Required Course Credit",
    required_course_ects_credit: "Required Course Ects Credit",
    reservation_add: "Reservation Add",
    reservation_system: "Reservation System",
    reservation_type: "Reservation Type",
    reservations: "reservations",
    reservation_information: "Reservation Information",
    reservation_form_detail: "reservation Form Detail",
    reset: "Reset",
    route:"Route",
    residence_address: "Residence Address",
    residence_city: "Residence City",
    residence_permit: "Residence Permit",
    residence_permit_end_date: "Residence Permit End Date",
    residence_permit_no: "Residence Permit No",
    residence_permit_start_date: "Residence Permit Start Date",
    residence_permit_validity_dates: "Residence Permit Validity Dates",
    residence_permit_validity_end_date: "Validity End Date",
    residence_permit_validity_start_date: "Validity Start Date",
    resident_signature: "Resident Signature",
    room_change_request:"Room Change Request",
    reviewed_by: "Reviewed By",
    request_data:"Request Data",
    request_detail:"Request Detail",
    result: "Result",
    results: "Results",
    role: "Role",
    role_permissions: "Role Permission",
    roles: "Roles",
    room_change_request_form: "Room Change Request Form",
    registered_student_count_by_school:"Registered Student Count by School",
    registration_numbers_by_faculty_report:
        "Registration Numbers By School Report",
    room_type: "Room Type",
    request_details: "Request Details",
    religious_exemption:"Religious Exemption",
    religious_exemption_text:"Religious Exemption Text",
    reservation_number:"Reservation Number",
    re_enrollment: "Re-enrollment",
    room: "Room",
    room_type_name:"Room Type Name",
    return_semester:"I expect to Return in the Semester/Year of",
    leave_of_absence_select:"Select the reason for Leave of Absence:",

    // S
    state: "State",
    s_grade_conversion: "S Grade Conversion",
    saturday: "Saturday",
    save: "Save",
    save_and_continue: "Save and Continue",
    save_preferences: "Save Preferences",
    saved: "Saved",
    scala_id: "Scale ID",
    scala_name: "Scale Name",
    scholarship_detail: "Scholarship Detail",
    scholarship_rate: "Scholarship Rate",
    scholarship_status: "Scholarship Status",
    scholarship_type: "Scholarship Type",
    scholarships: "Scholarships",
    scholarshiptypes: "Type of Scholarship",
    scholor_ship_rate_ration: "Without Scholarships",
    school: "School",
    school_name: "Name of School",
    school_certificate: "School Certificate",
    scientific_preparation: "Scientific Preparation",
    scientific_preparation_courses: "Bilimsel Hazırlık Dersleri",
    score: "Score",
    senior: "Senior",
    score_information: "Test Score Information",
    score_type: "Type of Score",
    score_with_the_selected_program: "Score With The Selected Program",
    search: "Search",
    search_course: "Search Course",
    seating_types: "Seating Types",
    section: "Section",
    section_detail: "Section Detail",
    section_not_found: "Section Not Found",
    section_count: "Number of Sections",
    section_id: "section Id",
    section_information: "Section Information",
    section_definition: "Section Definition",
    section_operations: "SECTION Operations",
    section_type: "Section Type",
    scholarship_tuition_assistance: "Scholarship/Tuition Assistance",
    scholarship_tuition_assistance_checkbox: "I confirm that the information given in this form is true, complete and accurate  ",


    scholarship_tuition_assistance_text: "Total of 12960 USD for 36 credits. Per credit amount (total scholarship/tuition assistance divided by total credits) is applied to the credits enrolled during each course registration period. Repeat credits and additional credits are exempt of the use of such scholarship/tuition assistance credits.<br><br>Students who earn a scholarship of any category should meet the following criteria to maintain the scholarship:<br>1) Continuously enroll in courses to meet graduation requirements (unless taking an approved Leave of Absence), and<br>2) Maintain a minimum Cumulative Grade Point Average (CGPA) of 2.0 at Undergraduate and 3.0 at Graduate level for any semester, and<br>3) Avoid any disciplinary action for any reason.<br><br>Scholarships/tuition assistance are available using the university’s funds allocated for students who need additional financial aid. That is why continuing one’s studies at BAU is important as another student might have been rejected at the time of one’s application. Therefore, if a student who was awarded any scholarship/tuition assistance withdraw from BAU for any reason, they are required to pay the award amount back for all course credits they have completed since the scholarship/tuition assistance was awarded.",

    sections: "Sections",
    section_import_excel:
        "Course Code, Section, Instructor Id, Quota , Type, Section Language",
    section_detail_import_excel:
        "Course Code, Section, Detail Type, Detail Code, Detail, Invert",
    section_update_success:
        "{name} {surname} student's section has been updated to {section}.",
    section_update_unsuccess:
        "{name} {surname} student's section could not be updated to {section}.",
    select: "Select",
    session_information:"Session information",
    select_btn: "Select",
    select_date: "Select Date",
    select_file: "Select File",
    select_all: "Select All",
    select_is_cash_or_installment: "Peşin mi taksitli mi olduğunu seçiniz",
    current_students_not_registered:"Current Students Not Registered",
    students_who_have_not_enrolled_in_any_classes_in_the_current_semester:
        "Students Who Have Not Enrolled in Any Classes in the Current Semester",
    select_it: "Select",
    select_program: "Select Program",
    selected_field_is_invalid: "Selected %{numberof} field invalid",
    select_report_type: "Select Report Type",
    select_image_upload: "Select the image you want to upload",
    semester: "Semester",
    semester_1: "Academic Year/Semester",
    semester_count: "Number of Semesters",
    semester_registration_information: "Semester / Registration Information",
    semester_settings: "Semester Settings",
    semester_status: "Semester Status",
    semester_text: "Semester",
    semester_text_en: "Semester (EN)",
    semester_title: "Semester",
    semester_x: "Semester {semester}",
    semesterdate: "Semester Dates",
    semesters: "Semesters",
    semester_info: "Semester Information",
    send: "Send",
    semester_add: "Add Semester",
    send_again: "Send Again",
    send_again_btn: "Send Again (button)",
    send_again_not: "Click on the Send Again button and try again",
    send_approve: "Send Approve",
    send_password: "Send Password",
    send_request: "Send Request",
    send_sms: "Send SMS",
    send_verification_code: "Send Verification Code",
    send_yoksis_transcript: "Send Yoksis Transkrip",
    services: "Services",
    service_animal:"Service Animal",
    schedule:"Schedule",
    sap_report: "SAP report",
    session: "Session",
    setting_up_program_fees: "Setting Up Program Fees",
    settlement_scholarship: "Settlement Scholarship",
    short_name: "Short Name",
    short_name_en: "Short Name (EN)",
    show: "Show",
    show_course: "Show Course",
    show_application: "Show/View Application",
    signature: "Signature",
    signature_name: "Signature Name",
    signature_type: "Signature Type",
    signer: "Signer",
    signer_name_surname: "Signatory",
    signer_national_id: "Signer ID Number",
    signer_title: "Signer Title",
    signer_title_en: "Signer Title(EN)",
    slot: "Slot",
    slot_type: "Slot Type",
    study_year: "Study Year",
    slot_approval_check: "Slot Approval Check",
    slot_type_code: "Slot Type Code",
    slot_types: "Slot Types",
    slots: "Slots",
    something_went_wrong: "An Error Has Occurred ",
    sorry_we_could_not_find_the_page_you_were_looking_for:
        "Sorry we could not find the page you were looking for",
    sort_by_graduation_grade_point_average:
        "Sort by Graduation Grade Point Average",
    sorting_by_semester_grade_point_average:
        "Sorting by Semester Grade Point Average",
    sound_systems: "Sound Systems",
    speaking_exam: "Speaking Exam",
    spring: "Spring",
    spring_2025: "Spring 2025",
    spring_credit: "Spring Credit",
    staff: "Staff",
    staff_information: "Staff Information",
    start: "Start",
    start_date: "Start Date",
    students_acknowledgement: "Students Acknowledgement",
    students_acknowledgement_text: " 1. I hereby acknowledge that I am responsible for reading the BAU Academic Catalog. The\n" +
        " Academic Catalog, which can be accessed through our website www.bau.edu, is included as part\n" +
        " of this enrollment agreement.<br><br>" +
        "2. I have carefully read and received an exact copy of this enrollment agreement.<br><br>" +
        " 3. I understand that the school may terminate my enrollment if I fail to comply with attendance,\n" +
        " academic, and financial requirements or if I fail to abide by established standards of conduct, as\n" +
        " outlined in the Academic Catalog. While enrolled in the school, I understand that I must maintain\n" +
        " satisfactory academic progress as described in the Academic Catalog, and that my financial\n" +
        " obligation to the school must be paid in full before a certificate may be awarded.<br><br>" +
        " 4. I understand that the school does not guarantee job placement to graduates upon program\n" +
        " completion or upon graduation.<br><br>" +
        " 5. I understand the complaints, which cannot be resolved by direct negotiation with the school in\n" +
        " accordance with its written grievance policy, may be filed with the<strong> Higher Education Licensure\n" +
        " Commission 1050 First Street, NE – 5th Floor Washington, DC 20002</strong>",
    start_proccess: "Start",
    start_time: "Start Time",
    statement1_error:
        "Verify the authenticity of the information entered on the record monitor/website",
    statement2_error:
        "Confirm the ClarificationText Regarding Processing of Personal Data",
    statement3_error:
        "Confirm the Explicit Consent Text Regarding Processing of Personal Data",
    statement_1:
        "I declare that the informatiıon I entered the record monitor is true and correct.",
    statement_2:
        "I confirm that I have read and understood the <a href=#>ClarificationText</a> regarding Precessing of Personal Data.",
    statement_3: "Explicit Consent Text Regarding Processing of Personal Data",
    statement_4:
        "Explicit Consent Text Regarding Processing of Personal Data(Pilotage)",
    status: "Status",
    stay_of_execution_decision: "Stay Of Execution Decision",
    step_name: "Step Name",
    status_approval_awaited: "Approval is Awaited",
    status_approved: "Status Approved",
    status_declined: "Status Declined",
    sort_by_semester_grade_point_average:"Sort By Semester Grade Point Average",
    status_update: "Status Update",
    status_uploaded: "Status Uploaded",
    status_waiting: "Status Waiting",
    status_waiting_publish: "Status Waiting Publish",
    street: "Street",
    street_and_no: "Street No",
    student: "Student",
    students_who_are_likely_to_graduate_in_the_next_3_semesters:
        "Students Who Are Likely to Graduate in the Next 3 Semesters",
    students_who_are_likely_to_graduate_in_the_next_2_semesters:
        "Students Who Are Likely to Graduate in the Next 2 Semesters",
    students_who_are_likely_to_graduate_next_semester:
        "Students Who Are Likely to Graduate Next Semester",
    students_who_are_likely_to_graduate_in_the_current_semester:
        "Students Who Are Likely to Graduate in the Current Semester",
    student_Document_create: "Student Document Create",
    student_documents: "Student Documents",
    student_affairs: "Student Affairs",
    student_certificate: "Student Certificate",
    student_collection_report: "Student Collection Report",
    student_count: "Number of Students",
    student_courses_title: "Course Analysis Report",
    student_document_create: "Create Student Document",
    student_email: "Student E-Mail",
    student_gno_simulation: "Student CGPA Simulation",
    student_gno_report: "Student CGPA Report",
    student_gsm: "Student GSM",
    student_graduation_requirement_report:
        "Student Graduation Requirement Report",
    student_group: "Student Group",
    student_grade_entry_failed:
        "Grade entry  failed for student number {number} and name {name}",
    student_information: "Student Information",
    student_information_system: "Student Information System",
    student_list: "Student List",
    student_list_report: "Student List Report",
    student_list_with_photo: "Student List (with Photo)",
    student_mobile_number: "Student Mobile Number",
    student_name: "Student Name",
    student_surname: "Student Surname",
    student_number: "Student Number",
    student_id: "Student ID",
    student_number_format: "Student Number Format",
    student_number_long: "Student Number",
    sophomore: "Sophomore",

    student_numbers: "Student Numbers",
    student_notes_report: "Student Notes Report",
    student_program_class_success_title: "Class Success Rank",
    student_program_graduation_success_title: "Graduation Success Rank",
    student_report: "Student Report",
    student_reports: "Student Reports",
    student_section_change: "Student Section Change",
    student_sections: "Student Sections",
    student_semester_count: "Student Semester Count",
    student_semester_report: "Semester Status Report",
    student_slots: "Student Slots",
    student_status: "Student Status",
    student_status_btn: "Student Status ",
    student_attendance_records:"Student Attendance Records",
    student_attendance_records_report:"Student Attendance Records Report",
    student_card: "Student Card",
    staff_card: "Staff Card",
    students: "Students",
    student_program_id: "Student Program ID",
    studied: "Studied",
    subject: "Subject",
    subject_en: "Subject EN",
    success: "Success",
    successful: "Successful",
    student_reports_parchment_report:"Parchment Report",
    success_data_count: "Success_Data_Count",
    success_rate: "Success Rates",
    success_status: "Success Status",
    success_status_report: "Success Status Report",
    successfully_added: "Successfully Added",
    successfully_changed: "Successfully Changed",
    successfully_deleted: "Successfully Deleted",
    survey_result_download:"Survey Result Download",
    sum_of_credits: "Sum of Credits",
    sum_of_ects_credits: "Sum of ECTS Credits",
    summary: "Summary",
    summary_of_project: "Summary of Project",
    summary_of_thesis: "Summary of Thesis",
    summary_statement_content: "Summary Statement Content",
    summer: "Summer",
    summer_2025: "Spring 2025",
    sunday: "Sunday",
    surgery_illness_history: "History of any Surgery or Chronic Diseases",
    surgery_or_discomfort: "Surgery or Chronic Diseases if any ",
    surname: "Last Name ",
    surname_placeholder: "Enter your Surname",
    survey: "Survey",
    survey_questions: "Survey Questions",
    student_verification_letters:"Student Verification Letters",
    surveys: "Surveys",
    switch_user: "Switch User",
    synchronous: "Synchronous",
    system_name: "System Name",
    speacial_student_error: "Speacial Students Can Not Register Courses",
    schedule_overlap_message:
        "The course you want to take overlaps with #course.",
    supervisor_approvals: "Supervisor Approvals",
    send_to_quickbooks: "Send to Quickbooks",
    signatures: "Signatures",
    submit: "Submit",
    student_application_form: "Student Application Form",
    student_type: "Student Type",
    student_tenant: "Student (Tenant)",
    student_signature: "Student Signature",
    safety: "Safety",
    security: "Security",
    show_request_form:"Show Request Form",
    street_1:"Street 1",
    street_2:"Street 2",
    send_email_to_students: "Send E-mail to Students",

    // T
    take_the_lesson: "Select Course",
    taking_during_active_semester: "Taking During Active Semester",
    tc_no: "Identity No",
    tc_or_passport: "Identity / Passport No",
    teaching_staff: "Instructor",
    teaching_staff_no: "Teaching Staff No",
    temporary_graduation: "Temporary Graduation Certificate",
    telephone: "Phone",
    phone_number: "Phone Number",
    terminal: "Terminal",
    the_class_finished_in_the_program_came: "Thesis Title",
    the_person_who_person: "Added By Filter",
    the_values_you_entered_have_already_been_added:
        "The Values You Entered Have Already Been Added",
    theoretical: "Theoretical",
    theoretical_credit: "Theoretical Credit",
    theoric: "Theoretical",
    theoric_section: "Teoric Section",
    there_are_no_records: "No record found",
    there_is: "There is",
    there_is_any_course_in_active_semester:
        "Course not found in the Active Semester",
    there_is_no_authorization: "There is no authorization",
    thesis_advisor: "Thesis Advisor",
    thesis_advisory: "Thesis Advisory",
    thesis_and_project: "Thesis and Project",
    thesis_and_project_advisor_approve_title:
        " View Coordinator/ Advisor Applications",
    thesis_and_project_application: "Thesis & Project Application",
    thesis_and_project_coordinator_approve_title:
        "Thesis & Project Coordinator Approve Title",
    thesis_and_project_info: "Thesis & Project Information",
    thesis_and_projects_admin_title: "Thesis and Projects Admin",
    thesis_and_projects_admin_update: "Thesis and Projects Admin Update",
    thesis_students: "Thesis Students",
    thesis_title: "Thesis Title",
    this_field_is_required: "This field is required.",
    thursday: "Thursday",
    title1: "Title",
    title: "Title",
    title_of_project: "Title of Project",
    title_of_thesis: "Title of Thesis",
    title_title: "Title",
    total_credit_required_for_graduation: "Total Credit Required for Graduation",
    to_score: "To Score",
    to_email: "To E-Mail",
    toefl_password: "Toefl Password",
    toefl_username: "Toefl Username",
    total_days:"Total Days",
    total_advisory: "Total Advisory",
    total_amount: "Total Amount",
    total_course_count: "Total Course Count",
    total_credits: "Total Credits",
    total_credits_attempted: "Total Credits Attempted",
    total_credits_earned: "Total Credits Earned",
    total_debt: "Total Debt",
    total_discount: "Total Scholarship ",
    total_discount_amount: "Total Scholarship  Amount",
    total_discount_rate: "Total Scholarship  Rate",
    total_ects_credits: "Total AKTS Credits",
    total_ects_credits_attempted: "Total ECTS Credits Attempted",
    total_ects_credits_earned: "Total ECTS Credits Earned",
    total_installment: "Total Installment",
    total_paid: "Total Paid",
    total_points: "Total Points",
    total_refund: "Total Refund",
    total_slots: "Total Slots",
    transaction_successful:"Transaction Successful",
    total_student_count: "Total Number of Students",
    total_capacity: "Total Capacity",
    transaction_date: "Transaction Date",
    transfer_places:"Route",
    transfer_places_name:"Route",
    transaction_start_date: "Transaction Start Date",
    transaction_end_date: "Transaction End Date",
    transaction_reference: "Transaction Reference",
    transcript: "Transcript",
    official_transcript: "Official Transcript",
    official_transcript_for_parchment: "Official Transcript for Parchment",
    true: "True",
    tuesday: "Tuesday",
    tuition: "Tuition Fee",
    turkey: "Turkey",
    turkish: "Turkish",
    turn_back: "Go Back",
    type: "Type",
    type_detail: "Type Detail",
    type_of_delivery: "Type of Delivery",
    type_of_program_score: "Type of Program Score",
    todays_date:"Today's Date",
    term:"Term",
    tenant_type: "Tenant Type",

    // U
    usa_address: "USA Address",
    username_info_update: "Username Info Update",
    unauthorized_operation: "Unauthorized Operation",
    undergraduate_transfer: "Undergraduate Transfer",
    undergraduate_transfer_applications: "Undergraduate Transfer Applications",
    undergraduate_transfer_quotas: "Undergraduate Transfer Quotas",
    undergraduate_vertical: "Transfer to University Transition",
    undergraduate_vertical_transfer: "Transfer to University Transition",
    unit: "Unit",
    unit_id: "Unit İd",
    units: "Units",
    universities: "Universities",
    university: "University",
    unofficial_transcript:"Unofficial Transcript",
    university_status: "Domestic / Abroad",
    university_courses: "University Courses",
    university_grade_scale_matching: "University Grading Scale Matching",
    undergraduate_students_who_take_less_than_full_load_of_courses:
        "Undergraduate Students Who Take Less Than Full Load of Courses",
    university_informations: "University Information",
    university_rank: "University Ranking",
    university_type: "University Type",
    university_where_the_course_was_taken:
        "University where the course was taken",
    unpublished: "Unpublished",
    update: "Update",
    update_classes_title: " Class Update Process",
    update_draft: "Update Draft",
    update_info_btn: "Update Contact Information",
    update_register: "Student Registration Update.",
    updated_at: "Updated At",
    us_address:"US Address",
    us_address_optional:"US Address (Optional)",
    updated: "Updated",
    upload: "Upload",
    upload_file: "Uploading File",
    upload_your_documents: "Download your documents",
    uploaded_files: "Uploaded Files",
    unsuccessful: "Unsuccessful",
    url: "URL",
    used_drugs: "Medicine you take regularly if any",
    user_quide: "User Guide",
    user_type: "User Type",
    username: "Username",
    user_transfer: "User Transfer",
    upload_sevis_data: "Upload SEVIS Data",
    student_semester_status_update: "Student Semester Status Update",
    urgency: "Urgency",
    user_id: "User ID",

    // V
    validity_dates: "Validity Period",
    validity_season_end: "Validity Season End",
    validity_season_start: "Validity Season Start",
    validity_date_start: "Validity Start Date",
    validity_date_end: "Validity End Date",
    value: "Value",
    virtual_internship_report:"Virtual Internships Report",
    verbal_score: "Verbal_Score",
    verification: "Verification",
    verification_code: "Verification Code",
    verification_method: "Verification Method",
    verification_method_not_found: "Verification Method Not Found",
    verification_personal_info: "Verification with Personal Info",
    verification_sms_code: "Verification with SMS Code",
    verified: "Verified",
    verify: "Verify",
    verify_code_again_not: "Verification Code Sent Again",
    vertical_transfer: "Vertical Transfer",
    view: "View",
    village: "Village",
    virman: "Virman",
    virtual_pos: "Virtual Pos",
    view_and_edit: "View And Edit",

    // W
    waiters_approved: "Approved Applications",
    waiters_for_approve: "Applications Pending Approval",
    waiters_rejected: "Applications Rejected",
    want_leave_of_absence:
        "Will There Be a Request to Apply for Leave of Absence?",
    want_leave_of_absence_missing: "Want_Leave_Of_Absence_Missing",
    want_optional_prep: "Want 0ptional Prep",
    want_optional_prep_missing: "Want_Optional_Prep_Missing",
    warning: "Warning",
    work_order_request:"Work Order Request",
    warning_file:
        "The file you will upload must be in jpg, jpeg, png, pdf, doc, docx format.",
    warning_file2:
        "The file you will upload must be in png,jpg,jpeg,pdf,doc,docx,mp4,avi,mov,mkv max:10240",
    wednesday: "Wednesday",
    week: "Week",
    weekend_in: "Weekend In",
    weekend_out: "Weekend Out",
    welcome: "Welcome",
    welcome_letter: "Welcome Letter",
    welcome_to: "Welcome to",
    when_to_administer: "When to Administer",
    whole_price: "Whole Price",
    wire: "Wire / (EFT) Electronic Funds Transfer ",
    with_installment: "With Installment",
    with_mobile_number: "With Mobile Number",
    with_personal_information: "With Personal Information",
    with_thesis: "With Thesis",
    with_without_thesis: "With Without Thesis",
    withdraw_requests: "Withdraw Requests",
    withdraw_request_official_notice: "This form constitutes an official written notice for the purpose of withdrawing from\n" +
        "                                    a course offered at Bay Atlantic\n" +
        "                                    University, Washington D.C. The official withdrawal date, for the purpose of a\n" +
        "                                    refund calculation, will be taken from the\n" +
        "                                    date the written notice is received by BAU.",
    withdraw_request_policy_acknowledgment: "I have read the academic catalog and understood the terms and conditions of the course withdrawal policy.",
    withdraw_request_policy_list: "<ul>\n" +
        "                                    <li>The submission date of this form is considered the official withdrawal\n" +
        "                                        date.\n" +
        "                                    </li>\n" +
        "                                    <li>A \"W\" grade is given for withdrawals between the last drop day and before\n" +
        "                                        the end of the 10th week of class.\n" +
        "                                    </li>\n" +
        "                                    <li>Although the W grade does not affect GPA, it is counted towards credits\n" +
        "                                        attempted.\n" +
        "                                    </li>\n" +
        "                                    <li>Dropping all courses before the 10th day of class leads to administrative\n" +
        "                                        withdrawal.\n" +
        "                                    </li>\n" +
        "                                    <li>Non-attendance or instructor notification does not suffice for official\n" +
        "                                        withdrawal and may result in an \"F\" grade.\n" +
        "                                    </li>\n" +
        "                                    <li>Withdrawals are not allowed after the 10th week of class.</li>\n" +
        "                                    <li>F1 visa students must maintain full-time status and are not eligible to\n" +
        "                                        withdraw from courses.\n" +
        "                                    </li>\n" +
        "                                    <li>\n" +
        "                                        Any associated withdrawal charges and fees will be subject to the\n" +
        "                                        University's refund policy.\n" +
        "                                    </li>\n" +
        "                                </ul>",
    without_thesis: "Without Thesis",
    witness_complainant_statement: "Witness Complainant Statement",
    working_end_date: "Ending Date of Employment",
    working_start_date: "Starting Date of Employment",
    working_status: "Employment Status",
    write_verify_code: "Enter Verification Code sent to your mobile phone",
    written_and_oral_defense_report: "Written And Oral Defense Report",
    written_exam: "Written Exam",
    work_order_request_form: "Work Order Request Form",
    workload: "Workload",
    witness_persons:"Witness Persons",
    withdrew: "Withdrew",
    work_order_files:"Work Order Files",
    // Y
    year: "Year",
    year_code: "Year Code",
    year_count: "Year Count",
    year_of_birth: "Year of Birth",
    yearly: "Yearly",
    yes: "Yes",
    yl_payment_plan: "YL Payment Plan",
    yoksis: "Yoksis",
    yoksis_code: "Yoksis Code",
    yoksis_import_title: "YOKSİS Import",
    yoksis_information_cancel: "Yoksis Information Cancel",
    yoksis_information_import: "Yoksis Information Import",
    yoksis_information_update: "Yoksis Information Update",
    yoksis_number_of_semesters: "Yoksis Number of Semesters",
    yoksis_unit_id: "Yoksis Unit Id",
    yoksis_units: "Yoksis Units",
    yok_registration_status_report: "YOK Registration Status Report",
    you_can_combine_up_to_five_lessons: "You can combine up to five lessons",
    you_are_not_authorized_for_this_operation:
        "You are not authorized for this operation.",
    you_can_try_again_after_1_minute: "You can try again after 1 minute.",
    you_dont_have_a_payment_plan: "You do not have a Payment Plan",
    you_have_to_upload_the_files_first: "You have to upload the files first!",
    you_have_tried_too_many: "You have tried too many.",
    you_must_fill_in_the_fields_indicated_in_red:
        "You are required to fill in the fields indicated in red",
    you_must_do_change: "You must make changes",
    your_chosen_courses: "Courses Selected",
    your_payment_transaction_has_been_successfully_completed:
        "Your payment transaction has been successfully completed",
    your_preferences: "Your Preferences",
    your_mailing_us_address: "Your Mailing U.S. Address",
    your_programs: "Your Programs",
    your_selected_permission: "Your Selected Permission",
    your_selected_program: "Program You Selected",
    your_selected_classrooms: "Your Selected Classrooms",
    you_dont_have_any_invoice: "You don`t have any invoices",
    you_have_completed_all_courses_text:
        "You have completed all courses for this slot type.",
    exam_schedules_double_course_control:
        "Exam Schedule / Double Course Control",
    double_course_control: "Double Course Control",
    exam_schedule_report: "Courses Not Included in the Exam Schedule",
    isThereExamSchedule: "Is there an exam schedule?",
    group_name: "Group Name",
    group_name_en: "Group Name (EN)",
    // Z
    zip_code: "Zip Code",
    zip: "Zip",
    zip_code_note:"* Zip Code should match the credit card statement zip code",
    document_images: {
        favicon: " Application Icon",
        "document-logo": "Document Logo",
        background: "Login Page Background",
        logo: "Login Page Logo",
        "menu-logo": "Menu Logo",
    },
    fees: {

        "mandotory_semester_fess": "",

        "student_activities_and_services": "",
        "technology_fee": "",

        "mandatory_one_time_fee": "",
        "student_id_card": "<div class='d-flex justify-content-between'>",
        "as_applicable_fees_paid_only_when_students_use_these_services": "",

        "late_registration_fee_per_credit": "",
        "late_payment_fee_per_credit": "",
        "bau_english_proficiency_test": "",
        "replacement_student_id_card": "",
        "transcript_processing_fee_per_transcript": "",
        "returned_check_fee": "",
        "cancellation_fee": "",
        "international_postage_of_documents": "",
        "administrative_services_fee": "",
        "student_housing_security_deposit": "",
        "cap_and_gown": "",
        "graduation_application_fee": "",
        "diploma_replacement_fee": "",
        "fess_text": "* When students cancel their enrollment within 3 business days of the beginning of a semester.\n" +
            "**Only students who receive full scholarship of any kind defined in the scholarship section are\n" +
            "required to pay.\n" +
            "Tuition and fees are not kept stable for cohorts upon registration. Tuition and fees may change\n" +
            "each year based on the Board of Trustee’s decision. Students are required to check the webpage\n" +
            "and the academic catalog during course registration.\n" +
            "I have read and I confirm the Tuition and Fees Section",


    },
    fess_checkbox_label:"I have read and I confirm the Tuition and Fees Section"
};

export default translations;
