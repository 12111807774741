const routes = [
    {
        path:"/application/withDraw/course",
        component:()=> import("../pages/applicationWithDraw"),
        meta:{
            isAuthenticated:true,
            isStudent: true
        }
    },
    {
        path:"/request/withDraw",
        component:()=> import("../pages/requestsWithDraw"),
        meta:{
            isAuthenticated:true,
            permission: "withdraw_index"
        }
    },
    {
        path:"/request/withDraw/detail/:id",
        component:()=> import("../pages/detailRequestWithDraw"),
        meta:{
            isAuthenticated:true,
            permission:"withdraw_show"
        }
    },
    {
        path: "/withDraw/request/form/preview/:id",
        component: () => import("../pages/RequestFormWithDraw.vue"),
        meta: {
            isAuthenticated: true,
            isStudent: true
        }
    }

]
export default routes;
