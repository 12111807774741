import {getSuccessRoutePage, setSuccessRoutePage} from "@/modules/housingReservationForm/router/middleware";

const routes = [
    {
        path: "/housing/reservation/form",
        component: () => import("../pages/Index"),
        meta: {}
    },
    {
        path: "/housing/reservation/form/verified",
        component: () => import("../pages/Form.vue"),
        meta: {}
    },
    {
        path: "/housing/reservation/form/success",
        component: () => import("../pages/Success.vue"),
        meta: {},
        beforeEnter: (to, from, next) => {
            if(getSuccessRoutePage()){
                setSuccessRoutePage(false)
                next()
            }else{
                next({
                    path: '/housing/reservation/form'
                })
            }
        }
    },
];

export default routes;
