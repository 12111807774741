import HousingReservationsListService from '@/services/HousingReservationsListService'

const module = {
    namespaced: true,
    state: () => ({
        token: null,
        timerId:null
    }),
    getters: {
        getReservationToken() {
            return JSON.parse(localStorage.getItem('tenant_token'))
        },
    },
    mutations: {
        setReservationToken(state, token) {
            state.token = token;
        },
        setTime(state, time) {
            state.time = time;
        },
        setUser(state, user) {
            state.user = user;
        },
        clearUserAndToken(state) {
            state.user = {}
            state.token = null;
        },
        setTimer(state, id) {
            state.timerId = id;
        },
        clearTimer(state) {
            state.timerId = null;
        },

    },
    actions: {
        async setReservationToken({commit,dispatch}) {
            await HousingReservationsListService.studentToken()
                             .then(response => {
                                 const token =response.data.data.token
                                 commit('setReservationToken',token)
                                 localStorage.setItem('tenant_token',JSON.stringify(token))
                                 dispatch('startTimer')

                             })

        },
        startTimer({ commit ,state,dispatch }) {
                const id = setTimeout(() => {
                    localStorage.removeItem('tenant_token')
                    commit("clearTimer");
                    dispatch('stopTimer')
                }, 36000000);
                commit("setTimer", id);


        },
        stopTimer({ commit, state }) {
                clearTimeout(state.timerId);
                commit("CLEAR_TIMER");
        },
    }
}

export default module;
