import Vue from 'vue';
import Vuex from 'vuex';
import Base from '@/plugins/Base'

// Modules
import auth from '@/modules/auth/store/index'
import dashboard from '@/modules/dashboard/store/index'
import epayment from '@/modules/epayment/store/index'
import onlineRegistration from '@/modules/onlineRegistration/store/index'
import students from '@/modules/students/store/index'
import undergraduateTransfer from '@/modules/undergraduateTransfer/store/index'
import users from '@/modules/users/store/index'
import i18n from '@/plugins/i18n'
import graduateApplication from '@/modules/graduateApplication/store/index'
import enrollmentAgreementForm from "@/modules/enrollmentForm/store/index";
import housingReservationForm from "@/modules/housingReservationForm/store/index";
import roomReservationSystem from "@/modules/roomReservationSystem/store/index"
import studentHousingReservation from "@/modules/AvailabilityOfRooms/store/index"


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth: auth,
        dashboard: dashboard,
        epayment: epayment,
        onlineRegistration: onlineRegistration,
        students: students,
        undergraduateTransfer: undergraduateTransfer,
        user: users,
        graduateApplication: graduateApplication,
        enrollmentAgreementForm:enrollmentAgreementForm,
        housingReservationForm,
        roomReservationSystem:roomReservationSystem,
        studentHousingReservation:studentHousingReservation
    },
    state: () => ({
        authLayout: {},
        appLayout: {
            sideBarExpandedWrapper: false
        },
        nightMode: false,
        locale: 'en',
        confirmModal: {
            show: false,
            message: '',
            callback: null
        },
        deleteModal: {
            show: false,
            callback: null
        }
    }),
    getters: {
        // App Layouts
        getAppLayoutSideBarExpandedWrapper(state) {
            return state.appLayout.sideBarExpandedWrapper;
        },

        // Locale
        getLocale(state) {
            return state.locale;
        },

        // Night Mode
        getNightMode(state) {
            return state.nightMode;
        },

        // Modals
        showConfirmModal(state) {
            return state.confirmModal.show;
        },
        confirmModalMessage(state) {
            return state.confirmModal.message;
        },
        confirmModalCallback(state) {
            return state.confirmModal.callback;
        },
        showDeleteModal(state) {
            return state.deleteModal.show;
        },
        deleteModalCallback(state) {
            return state.deleteModal.callback;
        }
    },
    mutations: {
        setAppLayout(state, data) {
            state.appLayout = data;
            Base.LocalStorage.set('appLayout', state.appLayout);
        },
        setLocale(state, locale) {
            Base.LocalStorage.set('locale', locale);
            i18n.locale = locale
            state.locale = locale;
        },
        setNightMode(state, data) {
            state.nightMode = data;
            Base.LocalStorage.set('nightMode', state.nightMode);
        },
        changeAppLayoutSidebarExpandedWrapper(state) {
            state.appLayout.sideBarExpandedWrapper = !state.appLayout.sideBarExpandedWrapper;
            Base.LocalStorage.set('appLayout', state.appLayout);
        },
        changeNightMode(state) {
            state.nightMode = !state.nightMode;
            Base.LocalStorage.set('nightMode', state.nightMode);
        },
        setConfirmModal(state, obj) {
            state.confirmModal.message = obj.msg;
            state.confirmModal.show = true;
            state.confirmModal.callback = obj.callback;
        },
        closeConfirmModal(state) {
            state.confirmModal.show = false;
            state.confirmModal.callback = null;
        },
        setDeleteModal(state, obj) {
            state.deleteModal.show = true;
            state.deleteModal.callback = obj.callback;
        },
        closeDeleteModal(state) {
            state.deleteModal.show = false;
            state.deleteModal.callback = null;
        }
    },
    actions: {
        // Locale
        initLocale({commit}){

            let locale = Base.LocalStorage.get('locale');
            if (locale && ['tr', 'en'].includes(locale)) {
                commit('setLocale', locale);
            }
            else {
                commit('setLocale', 'en');
            }
        },


        // Night Mode
        initNightMode({commit, state}) {

            let nightMode = Base.LocalStorage.get('nightMode');
            if (nightMode) {
                commit('setNightMode', nightMode);
            }
            else {
                Base.LocalStorage.set('nightMode', state.nightMode);
            }
        },

        // App Layouts
        initAppLayouts({commit, state}) {

            let appLayout = Base.LocalStorage.get('appLayout');
            if (appLayout) {
                commit('setAppLayout', appLayout);
            }
            else {
                Base.LocalStorage.set('appLayout', state.appLayout);
            }

        }
    }
});
