const routes = [
    {
        path: "/my-profile",
        component: () => import("../pages/student/MyProfile"),
        meta: {
            isAuthenticated: true,
            isStudentOrAlumni: true
        }
    },
    {
        path: "/my-course-schedule",
        component: () => import("../pages/student/MyCourseSchedule"),
        meta: {
            isAuthenticated: true,
            isStudent: true
        }
    },
    {
        path: "/my-courses",
        component: () => import("../pages/student/MyCourses"),
        meta: {
            isAuthenticated: true,
            isStudent: true,
            isMyCourses:true
        }
    },
    {
        path: "/students",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "student_index"
        }
    },
    {
        path: "/students/:id",
        component: () => import("../pages/student/Index"),
        meta: {
            isAuthenticated: true,
            permission: "student_show"
        },
    },
    {
        path: "/students/calculate/gpa",
        component: () => import("../pages/CalculateGpa"),
        meta: {
            isAuthenticated: true,
            permission: "student_calculategpa"
        }
    },
];

export default routes;
