const routes = [
    {
        path: "/housing/request/list",
        component: () => import("../pages/Index.vue")
    },
    {
        path: "/housing/requests",
        component: () => import("../pages/admin/Index.vue"),
        meta: {
            isAuthenticated: true,
            permission: "housingrequest_index",
        }
    }
]

export default routes;
